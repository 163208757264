import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {NgSelectModule} from '@ng-select/ng-select';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgxPayPalModule} from 'ngx-paypal';
import {ModalModule} from 'ngx-bootstrap/modal';

import {AccountingComponent} from './accounting.component';
import {BillsListComponent} from './bills/list';
import {AccountingRoutingModule} from './accounting-routing.module';
import {LoaderModule} from '../loader/loader.module';
import {SupportModule} from '../support';
import { BillViewComponent } from './bills/view';
import {InvoiceOwnerDataComponent} from './bills/view/invoice-owner-data';
import {ProfileModule} from '../profile';

@NgModule({
  imports: [
    CommonModule,
    AccountingRoutingModule,
    ReactiveFormsModule,
    LoaderModule,
    NgSelectModule,
    AngularSvgIconModule,
    SupportModule,
    TooltipModule,
    NgxPayPalModule,
    ModalModule.forRoot(),
    NgxIntlTelInputModule,
    FontAwesomeModule,
    ProfileModule
  ],
  exports: [
    BillsListComponent
  ],
  declarations: [
    AccountingComponent,
    BillsListComponent,
    BillViewComponent,
    InvoiceOwnerDataComponent
  ]
})
export class AccountingModule {
}
