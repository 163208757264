import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {CountryISO} from 'ngx-intl-tel-input';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import {AuthenticationService} from '../../_services';
import {Client} from '../../_models';
import { ClientCustomField, ClientCustomFields } from '../../_models/client-custom-fields';
import { SystemService } from 'src/app/_services/system.service';
import { GeoStructService } from 'src/app/_services/geo-struct.service';
import { GeoStruct } from 'src/app/_models/geo-struct';

@Component({
  selector: 'app-profile-details',
  templateUrl: 'profile-details.component.html'
})
export class ProfileDetailsComponent implements OnInit, OnDestroy {
  @Input() user: Client;
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() parentForm: FormGroup;
  @Input() edit = false;
  @Input() register = false;

  // TooltipLabel = TooltipLabel;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  currentLanguage = null;
  currentCountry = null;
  currentCountryPhone = null;
  countries = [];

  public posts: GeoStruct[] = [];
  public states: GeoStruct[] = [];

  public cities$: Observable<GeoStruct[]>;
  public citiesInput$ = new Subject<string>();
  public loadingCities = false;

  personType = 'private';
  customfields1 = ''; // Represents the e-bill opt-in and can be either 'on' or ''
  languages = [
    {id: 1, name: 'Hrvatski', code: 'croatian'},
    {id: 2, name: 'English', code: 'english'}
  ];
  public minSearchTermLength = 2;
  public clientCustomFields: ClientCustomFields;
  private subscription = new Subscription();

  constructor(
    protected authenticationService: AuthenticationService,
    private systemService: SystemService,
    private router: Router,
    private formBuilder: FormBuilder,
    private geoStructService: GeoStructService,
  ) {
    this.user = this.authenticationService.currentUserValue;
    this.clientCustomFields = this.systemService.clientCustomFieldsValue
    this.parentForm = (this.parentForm === undefined) ? this.formBuilder.group({
      phonenumber: [null, Validators.required],
      telephoneNumber: [{value: null, disabled: !this.edit}, Validators.required],
      marketingoptin: null,
      country: null,
      customfields1: null,
      customfields2: null,
    }) : this.parentForm;
    this.personType = this.user !== undefined && this.user !== null ? this.user.persontype : this.personType;
    this.customfields1 = this.user !== undefined && this.user !== null ? this.user.customfields1 : this.customfields1;

    this.subscription.add(
      this.systemService.clientCustomFields$.subscribe((customFields) => {
        this.clientCustomFields = customFields;
      })
    );
  }

  ngOnInit() {
    let phoneNumber = null;
    let defaultLanguage = 'croatian';
    let currentCountry = 'HR';
    let currentCountryPhone = 'HR';

    if (this.user) {
      if (this.user.subaccount) {
        return this.router.navigate(['/profile/contacts/' + this.user.id]);
      }
      const userLanguage = (typeof this.user.language === 'undefined') ? defaultLanguage : this.user.language;
      const userLang = this.languages.find(lang => lang.code === userLanguage);
      defaultLanguage = (userLang) ? userLang.code : defaultLanguage;
      currentCountry = (this.user.country !== undefined) ? this.user.country : currentCountry;
      currentCountryPhone = (this.user.countrycode !== undefined) ? this.user.countrycode : currentCountryPhone;
      phoneNumber = (this.user.phonenumber !== '') ? this.user.phonenumberformatted.split('.') : '';
      this.parentForm.patchValue({
        phonenumber: phoneNumber[1],
        telephoneNumber: phoneNumber[1],
        marketingoptin: this.user.marketingoptin,
        country: currentCountry,
        customfields1: this.user.customfields1,
        customfields2: this.user.customfields2,
      });
    }
    this.currentLanguage = this.languages.find(language => language.code === defaultLanguage);

    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }

    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);
    this.parentForm.patchValue({country: currentCountry});
    this.parentForm.patchValue({language: defaultLanguage});

    this.loadCities();
  }

  get f() {
    return this.parentForm.controls;
  }

  setClientPersonType(type: string) {
    if (this.user) {
      if (type === 'private') {
        this.user.companyname = null;
        this.parentForm.patchValue({companyname: null});
      }
      this.user.persontype = type;
      this.personType = type;
    } else {
      this.parentForm.patchValue({personType: type});
      this.personType = type;
    }

    // On any change to the person type, reset customfields
    this.customfields1 = '';
    this.parentForm.patchValue({
      customfields1: '',
      customfields2: '',
    });
  }

  setClientCustomField1(value: boolean) {
    this.customfields1 = value ? 'on' : '';
    this.parentForm.patchValue({
      customfields1: this.customfields1
    });
  }

  telInvalidPhone() {
    const defaultClass = (this.edit) ? 'form-control' : 'form-control-plaintext';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }

  public getCustomFieldBySystemName(systemName: string): ClientCustomField | null {
    if (this.clientCustomFields && this.clientCustomFields.customFields?.length > 0) {
      return this.clientCustomFields.customFields.find(field => field.systemname === systemName);
    }
    return null;
  }

  public trackById(item: any) {
    return item.id;
  }

  public loadCities(): void {
    this.cities$ = concat(
      of([]), // Default to empty array
      this.citiesInput$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.minSearchTermLength;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => (this.loadingCities = true)),
        switchMap((term) => {
          return this.geoStructService.getPlaces(term).pipe(
            catchError(() => of([])), // Set empty array on error
            tap(() => this.loadingCities = false)
          );
        })
      )
    );
  }

  public onCountryChange(event?: { code: string; name: string }): void {
    // Whenever the country changes, clear out the post code, city and state
    this.parentForm.patchValue({
      postcode: null,
      city: null,
      state: null,
    });

    // If the chosen country is not Croatia, remove customfield1 and customfield2 values
    if (event?.code !== 'HR') {
      this.parentForm.patchValue({
        customfields1: "",
        customfields2: "",
      });
    }
  }

  public onCityChange(city: GeoStruct | null): void {
    // On city change, try to find the state and fetch posts for the city
    if (city) {
      this.geoStructService
        .getStatesForAPlace(city.id)
        .subscribe((counties) => {
          this.states = counties;

          // If we have only one state, we can set it as the default value
          if (counties.length === 1) {
            const state = counties[0];
            this.parentForm.patchValue({ state: state.name });
          }

          // If we have multiple counties, we can show them in the dropdown
          if (counties.length > 1) {
            this.parentForm.patchValue({ state: null });
          }
        });

      this.geoStructService.getPostsForAPlace(city.id).subscribe((posts) => {
        this.posts = posts;

        // If we have only one post, we can set it as the default value
        if (posts.length === 1) {
          const post = posts[0];
          this.parentForm.patchValue({ postcode: post.code });
        }

        // If we have multiple posts, we can show them in the dropdown
        if (posts.length > 1) {
          this.parentForm.patchValue({ postcode: null });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
