<div class="bg-grey p-3" [formGroup]="permissionsForm">
  <h6 class="mb-4" i18n>Dozvole podračuna</h6>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="profile" type="checkbox" formControlName="profile" class="form-check-input"/>
        <label for="profile" i18n>Izmjena profila glavnog računa </label>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="contacts" type="checkbox" formControlName="contacts" class="form-check-input"/>
        <label for="contacts" i18n>Pregled i uređivanje kontakata</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="products" type="checkbox" formControlName="products" class="form-check-input"/>
        <label for="products" i18n>Pregled usluga i proizvoda</label>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="manageproducts" type="checkbox" formControlName="manageproducts" class="form-check-input"/>
        <label for="manageproducts" i18n>Plasiranje novih narudžbi, nadogradnji i zahtjeva za otkazivanje usluge</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="productsso" type="checkbox" formControlName="productsso" class="form-check-input"/>
        <label for="productsso" i18n>Single Sign-On</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="domains" type="checkbox" formControlName="domains" class="form-check-input"/>
        <label for="domains" i18n>Pregled domena</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="managedomains" type="checkbox" formControlName="managedomains" class="form-check-input"/>
        <label for="managedomains" i18n>Uređivanje domena</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="invoices" type="checkbox" formControlName="invoices" class="form-check-input"/>
        <label for="invoices" i18n>Pregled i plaćanje ponuda/računa</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="quotes" type="checkbox" formControlName="quotes" class="form-check-input"/>
        <label for="quotes" i18n>Pregled i prihvaćanje predračuna</label>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="tickets" type="checkbox" formControlName="tickets" class="form-check-input"/>
        <label for="tickets" i18n>Pregled i otvaranje upita za podršku</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="affiliates" type="checkbox" formControlName="affiliates" class="form-check-input"/>
        <label for="affiliates" i18n>Pregled i uređivanje suradničkih računa</label>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="emails" type="checkbox" formControlName="emails" class="form-check-input"/>
        <label for="emails" i18n>Pregled e-mail poruka</label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-6">
      <div class="form-check" *ngIf="source !== 'cart'">
        <input id="orders" type="checkbox" formControlName="orders" class="form-check-input"/>
        <label for="orders" i18n>Pregled i izmjena lozinki za usluge</label>
      </div>
    </div>
  </div>

  <p class="tac">
    <button type="button" class="btn rounded-light btn-outline-dark" (click)="selectAll()"><span *ngIf="!selectAllInputs" i18n>Označi sve</span><span *ngIf="selectAllInputs" i18n>Ukloni sve oznake</span></button>
  </p>
</div>
