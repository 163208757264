<div class="modal-header d-flex justify-content-between">
  <h3 i18n>Otkazivanje usluge</h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="cancelForm">
    <div class="form-group mb-3">
      <label for="reason" i18n>Molimo, ukratko opiši razloge za otkazivanje usluge</label>
      <textarea id="reason" aria-multiline="true" class="form-control" formControlName="reason"
                [ngClass]="{ 'is-invalid': submitted && f.reason.errors }" rows="6"></textarea>
      <div *ngIf="submitted && f.reason.errors" class="invalid-feedback w-100">
        <div *ngIf="f.reason.errors.required" i18n>Tekst razloga otkazivanja usluge je obavezan</div>
        <div *ngIf="f.reason.errors.minlength" i18n>Tekst razloga otkazivanja usluge mora biti dug minimalno 30 znakova
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <label for="cancelServiceOption" i18n>Način otkazivanja usluge</label>
      <select id="cancelServiceOption" class="form-control" formControlName="type">
        <option value="End of Billing Period" i18n>Otkazivanje na kraju obračunskog razdoblja</option>
        <option value="Immediate" i18n>Što hitnije otkazivanje usluge</option>
      </select>
    </div>
  </form>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button class="btn rounded-light" (click)="modalRef.hide()" i18n>Odustani</button>
  <button class="btn rounded-light btn-green" (click)="cancelService()" i18n>Zatraži otkazivanje usluge</button>
</div>
