import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import { APP_CONFIG, AppConfig } from "../app-config.module";
import { HttpRequest, HttpResponse } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { Subscription } from "rxjs";

interface IRequestResponse<T> {
  request: HttpRequest<T>;
  response: HttpResponse<T>;
}

@Injectable({
  providedIn: "root",
})
export class OpenReplayService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private tracker?: Tracker;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private zone: NgZone,
    private authenticationService: AuthenticationService
  ) {
    this.zone.runOutsideAngular(() => {
      this.tracker = new Tracker({
        projectKey: this.config.openReplay.projectKey,
        ingestPoint: this.config.openReplay.ingestPoint,
        __DISABLE_SECURE_MODE: this.config.openReplay.__DISABLE_SECURE_MODE,
        obscureTextNumbers: this.config.openReplay.obscureTextNumbers,
        obscureTextEmails: this.config.openReplay.obscureTextEmails,
        obscureInputEmails: this.config.openReplay.obscureInputEmails, // Obscure emails in input fields. Email values will be converted to a random chain of asterisks. Default: true.
        obscureInputDates: this.config.openReplay.obscureInputDates, // Obscure dates in input fields. Date values will be converted to a random chain of asterisks. Default: false.
        defaultInputMode: this.config.openReplay.defaultInputMode, //0 | 1 | 2 Default capture mode for input values. Respectively: plain, obscured or ignored. Default: 1 (obscured).
      });

      this.tracker.use(
        trackerAssist({
          callConfirm: {
            text: $localize`Agent želi započeti poziv.`,
            confirmBtn: {
              innerHTML: $localize`Prihvati`,
            },
            declineBtn: {
              innerHTML: $localize`Odbij`,
            },
          },
          controlConfirm: {
            text: $localize`Agent želi preuzeti kontrolu nad Vašim računalom.`,
            confirmBtn: {
              innerHTML: $localize`Prihvati`,
            },
            declineBtn: {
              innerHTML: $localize`Odbij`,
            },
          },
          recordingConfirm: {
            text: $localize`Agent želi snimati Vašu sesiju.`,
            confirmBtn: {
              innerHTML: $localize`Prihvati`,
            },
            declineBtn: {
              innerHTML: $localize`Odbij`,
            },
          },
        })
      );

      if(this.config.openReplay.start){
        this.tracker.start();
      } 
    });

    this.subscriptions.push(
      this.authenticationService.currentUser.subscribe((user) => {
        const currentUserEmail = user ? user.email : "anonymous";

        if (this.tracker) {
          this.tracker.setUserID(currentUserEmail);
        }
      })
    );
  }

  public getTracker(): Tracker | undefined {
    return this.tracker;
  }

  public sendEventToReplaySession(
    event: string,
    reqRes: IRequestResponse<any>
  ): void {
    this.zone.runOutsideAngular(() => {
      const { request, response } = reqRes;

      if (this.tracker) {
        this.tracker.event(event + " [request]", {
          method: request.method,
          url: request.url,
          params: request.params,
          headers: request.headers,
        });

        this.tracker.event(event + " [response]", {
          status: response.status,
          body: response.body,
          headers: response.headers,
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

    if (this.tracker) {
      this.tracker.stop();
    }
  }
}
