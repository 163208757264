import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Department} from './department';
import {HandleError, HttpErrorHandler} from '../../_services';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  handleError: HandleError;
  departments;

  constructor(
      private http: HttpClient,
      httpErrorHandler: HttpErrorHandler,
      @Inject(APP_CONFIG) private config: AppConfig,
      @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('DepartmentService');
  }

  getDepartments(): Observable<Department[]> {
    const data = {
      action: 'GetSupportDepartments',
      cache: true
    };
    if (!this.departments) {
      this.departments = this.http.post<HttpResponse<Department[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
        .pipe(
          map(res => {
            return (res.body.departments.department !== undefined) ? res.body.departments.department : [];
          }),
          catchError(this.handleError('getDepartments', []))
        );
    }
    return this.departments;
  }

  /**
   * Gets translated department name
   * @param department Department
   */
  public getName(department: Department): string {
    if (this.localeId !== 'hr') {
      const keyLocale = this.localeId === 'en' ? 'english' : 'hrvatski';
      return (typeof department.translated_data.translated_ticket_department_name[keyLocale]) ?
        department.translated_data.translated_ticket_department_name[keyLocale] : department.name;
    }
    return department.name;
  }
}
