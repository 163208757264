import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';

import {Subscription} from 'rxjs';

import {Reply} from '../reply';
import {TicketService} from '../ticket.service';

@Component({
  selector: 'app-ticket-reply',
  templateUrl: 'ticket-reply.component.html'
})
export class TicketReplyComponent implements OnDestroy {
  @ViewChild('downloadZipLink', {static: false}) private downloadZipLink: ElementRef;
  @Input() hiddenNumber: number;
  @Input() print = false;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() reply: Reply;
  @Input() hide: boolean;
  @Output() hideChange = new EventEmitter<boolean>();
  downloadingAttachment = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private service: TicketService
  ) {}

  displayAttachmentIcon() {
    return (typeof this.reply.attachment !== 'undefined' && this.reply.attachment.length > 0);
  }

  show() {
    this.hideChange.emit(false);
  }

  downloadAttachment(e, attachment) {
    e.preventDefault();
    this.downloadingAttachment = true;
    const attachmentSub = this.service.downloadAttachment(attachment.filename)
      .subscribe(res => {
        this.downloadingAttachment = false;
        const url = window.URL.createObjectURL(res);
        const link = this.downloadZipLink.nativeElement;
        link.href = url;
        link.download = attachment.name;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    this.subscription.add(attachmentSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
