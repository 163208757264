import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FileItem, FileUploader} from 'ng2-file-upload';

import {Department} from '../department';
import {AlertService} from '../../../_services';
import {DepartmentService} from '../department.service';
import {ProductService} from '../../../_services/product.service';

@Component({
    selector: 'app-ticket-details',
    templateUrl: './ticket-details.component.html'
})
export class TicketDetailsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() formData: FormData;
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() isNewTicket = true;

  private subscription: Subscription = new Subscription();
  uploader: FileUploader = new FileUploader({
    isHTML5: true,
    maxFileSize: 1024 * 1024 * 10,
    autoUpload: false,
    allowedMimeType: [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/tiff',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain',
      'application/vnd.rar',
      'application/x-rar-compressed',
      'application/octet-stream',
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip'
    ]
  });
  public hasBaseDropZoneOver = false;
  departments: Department[];
  services = [];
  loading = {
    departments: false,
    services: false
  };
  priorities = [
    {name: $localize`Nisko`, value: 'Low'},
    {name: $localize`Srednje`, value: 'Medium'},
    {name: $localize`Visoko`, value: 'High'}
  ];

  constructor(
    private departmentService: DepartmentService,
    private productService: ProductService,
    private alertService: AlertService
  ) {}

  get f() {
    return this.parentForm.controls;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  ngOnInit() {
    this.loading.departments = true;
    this.subscription.add(
      this.departmentService.getDepartments()
        .pipe(take(1))
        .subscribe( departments => {
            this.loading.departments = false;
            if (departments) {
              this.departments = departments;
            }
          }
        )
    );

    this.loading.services = true;
    this.subscription.add(
      this.productService.getClientProducts({limitstart: 0, limitnum: 200})
        .pipe(take(1))
        .subscribe( (services) => {
            this.loading.services = false;
            if (services) {
              if (services.length > 0) {
                services.forEach(singleService => {
                  if (singleService.domain !== '') {
                    singleService.name = singleService.name + ' (' + singleService.domain + ')';
                  }
                });
              }
              this.services = services;
            }
          }
        )
    );

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name === 'mimeType') {
        this.alertService.error($localize`Vrsta datoteke nije dozvoljena. Dozvoljene su samo sljedeće vrste: .jpg, .gif, .jpeg, .png, .pdf, .zip, .7z, .bmp, .doc, .docx, .rar`);
      }
      if (filter.name === 'fileSize') {
        this.alertService.error($localize`Datoteka mora imati manje od 10MB`);
      }
      if (filter.name !== 'mimeType' && filter.name !== 'fileSize') {
        this.alertService.error($localize`Došlo je do greške pri dodavanju datoteke ${filter.name}`);
      }
    };
    this.uploader.onAfterAddingFile = (file) => {
      this.formData.append('file[]', file._file);
    };
  }

  /**
   * Removes file item from form data files list.
   * @param item FileItem
   */
  removeFile(item: FileItem) {
    const formFiles = this.formData.getAll('file[]');
    if (formFiles.length) {
      const fileIndex = formFiles.indexOf(item._file);
      if (fileIndex > -1) {
        formFiles.splice(fileIndex, 1);
        item.remove();
        this.formData.delete('file[]');
        for (const fileItem of formFiles) {
          this.formData.append('file[]', fileItem);
        }
      }
    }
  }

  getName(department: Department): string {
    return this.departmentService.getName(department);
  }
}
