import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Pager} from '../support/tickets/pager';
import {Announcement} from '../_models/announcement';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {APP_CONFIG, AppConfig} from '../app-config.module';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.handleError = httpErrorHandler.createHandleError('AnnouncementsService');
  }

  /**
   * get a list of announcements
   */
  getAnnouncements(filterData: {}, pageSize: number | 10): Observable<{ pager: Pager, announcements: Announcement[] }> {
    const pager = new Pager();
    const keyPage = 'page';
    pager.currentPage = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;
    pager.pageSize = pageSize;

    const startIndex = (pager.currentPage === 1) ? 0 : (pageSize * (pager.currentPage - 1));
    const data = {
      action: 'GetAnnouncements',
      limitstart: startIndex,
      limitnum: pageSize,
      cache: true,
      language: this.localeId
    };

    return this.http.post<HttpResponse<Announcement[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          const total = res.body.totalresults;
          pager.totalPages = Math.ceil(total / pageSize);
          pager.pages = [...Array(Math.ceil(total / pageSize)).keys()].map(i => (i + 1));
          return {
            pager,
            announcements: res.body.announcements
          };
        }),
        catchError(this.handleError('GetAnnouncements', null))
      );
  }
}
