import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {HandleError, HttpErrorHandler} from '../../_services';
import {Announcement} from './announcement';
import {Ticket} from '../tickets';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    observe: 'response' as 'body'
};

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService {
    handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.handleError = httpErrorHandler.createHandleError('AnnouncementService');
    }

    /**
     * Gets one announcement from a list of announcements from API.
     * TODO: This needs to be reworked - find out if there is a individual announcement fetch method on API
     * @param id Announcement id in database on WHMCS
     */
    getAnnouncement(id: number): Observable<Announcement> {
        const data = {
            action: 'GetAnnouncements',
            limitnum: 1000
        };
        return this.http.post<HttpResponse<Ticket[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
            .pipe(
                map(res => {
                    if (res.body.announcements.announcement !== undefined) {
                        const announcementList = res.body.announcements.announcement as Announcement[];
                        for (const announcement of announcementList) {
                            if (announcement.id === id) {
                                announcement.announcementstripped = announcement.announcement.replace(/<[^>]*>/g, '');
                                return announcement;
                            }
                        }
                    }
                    return null;
                }),
                catchError(this.handleError('getList', null))
            );
    }

    /**
     * Gets a list of announcement and news articles.
     * @param filterData Filter data paramenters (limitstart (default 0) and limitnum (default 25))
     */
    getList(filterData: {}): Observable<Announcement[]> {
        const data = {
            action: 'GetAnnouncements'
        };
        return this.http.post<HttpResponse<Ticket[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
            .pipe(
                map(res => {
                    if (res.body.announcements.announcement !== undefined) {
                        const announcementList = res.body.announcements.announcement as Announcement[];
                        for (const announcement of announcementList) {
                            announcement.announcementstripped = announcement.announcement.replace(/<[^>]*>/g, '');
                        }
                        return announcementList;
                    }
                    return (res.body.announcements.announcement !== undefined) ? res.body.announcements.announcement : [];
                }),
                catchError(this.handleError('getList', []))
            );
    }
}
