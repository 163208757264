<div *ngIf="pager?.pages && pager?.totalPages > 1">
    <ul class="pagination flex-center flex-wrap">
        <li [ngClass]="{disabled:pager?.currentPage === 1}" class="page-item first-item">
            <a [routerLink]="[]" [queryParams]="{ page: 1 }" queryParamsHandling="merge" class="page-link" i18n>Prva</a>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === 1}" class="page-item previous-item">
            <a [routerLink]="[]" [queryParams]="{ page: pager?.currentPage - 1 }" queryParamsHandling="merge" class="page-link" i18n>Prethodna</a>
        </li>
        <li *ngFor="let page of pager?.pages" [ngClass]="{active:pager?.currentPage === page}" class="page-item number-item">
            <a [routerLink]="[]" [queryParams]="{ page: page }" queryParamsHandling="merge" class="page-link">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === pager?.totalPages}" class="page-item next-item">
            <a [routerLink]="[]" [queryParams]="{ page: pager?.currentPage + 1 }"  queryParamsHandling="merge" class="page-link" i18n>Sljedeća</a>
        </li>
        <li [ngClass]="{disabled:pager?.currentPage === pager?.totalPages}" class="page-item last-item">
            <a [routerLink]="[]" [queryParams]="{ page: pager?.totalPages }"  queryParamsHandling="merge" class="page-link" i18n>Zadnja</a>
        </li>
    </ul>
</div>
