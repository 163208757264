<div *ngIf="!isModal" class="d-flex justify-content-between align-items-center bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold mb-2 mb-lg-0 text-color-2" i18n>Moje kreditne kartice</h3>
  <button type="button" (click)="openModal(addNewCardModal, false, '')" class="btn btn-primary rounded-light" i18n>Kreiraj novu</button>
</div>

<div class="p-3">
  <div class="container-fluid">
    <div class="row d-flex align-items-stretch" *ngIf="paymentCards?.length; else noCreditCards">
      <div class="col-12 col-lg-6 col-xl-4 p-2" *ngFor="let paymentCard of paymentCards">
        <div class="card shadow rounded-light p-3 credit-card bg-white h-100">
         <div class="relative">
           <h4 class="title mb-3">
             <span *ngIf="paymentCard.description !== ''">{{paymentCard.description}}</span>
             <span *ngIf="paymentCard.description === ''">{{paymentCard.card_type}}</span>
           </h4>
           <div class="d-flex align-items-center mb-3">
             <div class="mr-3" *ngIf="paymentCard.description !== ''">
               <ng-container [ngSwitch]="paymentCard.card_type.toLocaleLowerCase()">
                 <!-- the same view can be shown in more than one case -->
                 <fa-icon [icon]="faCcVisa" size="4x" *ngSwitchCase="'visa'"></fa-icon>
                 <fa-icon [icon]="faCcMastercard" size="4x" *ngSwitchCase="'mastercard'"></fa-icon>
                 <fa-icon [icon]="faCcAmex" size="4x" *ngSwitchCase="'american express'"></fa-icon>
                 <fa-icon [icon]="faCcDinersClub" size="4x" *ngSwitchCase="'diners club'"></fa-icon>
                 <fa-icon [icon]="faCcDiscover" size="4x" *ngSwitchCase="'discover'"></fa-icon>
                 <fa-icon [icon]="faCcJcb" size="4x" *ngSwitchCase="'jcb'"></fa-icon>

                 <!--default case when there are no matches -->
                 <fa-icon [icon]="faCreditCard" size="4x" *ngSwitchDefault></fa-icon>
               </ng-container>
             </div>
             <div>
               <div><span class="col-3 mr-2" i18n>Zadnje 4 znamenke:</span> ****{{paymentCard.card_last_four}}</div>
               <div><span class="col-3 mr-2" i18n>Datum isteka:</span> {{paymentCard.expiry_date}}</div>
             </div>
           </div>

           <button *ngIf="!isModal" class="btn btn-sm absolute delete" tooltip="Izbriši karticu" i18n-tooltip="Izbriši karticu"
                   (click)="deletePayMethod(paymentCard, confirmDeletion)" i18n><svg-icon src="assets/svg/delete.svg" class="icon icon-30"></svg-icon></button>
           <button *ngIf="isModal" class="btn btn-sm btn-green rounded-light w-100 d-block" (click)="selectCard(paymentCard)" i18n>Odaberi</button>
         </div>
        </div>
      </div>
      <hr>
      <button *ngIf="isModal" class="btn btn-sm btn-green rounded-light w-100 d-block mt-3" (click)="openModal(addNewCardModal);" i18n>+ Nova kartica</button>
    </div>
    <ng-template #noCreditCards>
      <ng-container *ngIf="!loading">
        <div class="alert alert-info"  i18n>Nemaš spremljenih kreditnih kartica.</div>
        <app-card-new [modalRef]="modalRef"
                      [isModal]="true"
                      [invoice]="invoice"
                      (invoicePaid)="invoicePaid()"
                      (newCard)="selectCard($event)" *ngIf="isModal"></app-card-new>
      </ng-container>
    </ng-template>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</div>

<ng-template #confirmDeletion>
  <div class="modal-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h3 i18n>Brisanje kartice</h3>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" *ngIf="selectedPayMethod?.description">
    <p i18n>Sigurno želiš obrisati karticu <span class="font-weight-bold">{{selectedPayMethod.description}}</span>?</p>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn rounded-light" (click)="decline()" i18n>Odustani</button>
    <button type="button" class="btn rounded-light btn-danger" (click)="confirm()" i18n>Da</button>
  </div>
</ng-template>

<!--Used for adding cards in modal window with no redirection to /cards/new-->
<ng-template #addNewCardModal>
  <div class="modal-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h3 i18n>Dodaj novu karticu</h3>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-card-new [modalRef]="modalRef"
                [isModal]="newCardIsModal"
                [invoice]="invoice"
                (invoicePaid)="invoicePaid()"
                (newCard)="selectCard($event)"></app-card-new>
</ng-template>
