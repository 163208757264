import {
  AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy,
  OnInit, Output, TemplateRef, ViewChild, LOCALE_ID, Renderer2
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import {ScriptService} from '../../../_services/script.service';
import {APP_CONFIG, AppConfig} from '../../../app-config.module';
import {AlertService, AuthenticationService} from '../../../_services';
import {PaymentConfigData} from '../../../_models/payment-config-data';
import {PaymentService} from '../../../_services/payment.service';
import {Client} from '../../../_models';
import {Contact} from '../../contacts';
import {PayMethod} from '../../../_models/pay-method';
import {InvoiceService} from '../../../accounting/bills/invoice.service';
import {Invoice} from '../../../accounting/bills/invoice';

@Component({
  selector: 'app-card-new',
  templateUrl: 'card-new.component.html'
})
export class CardNewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() contact: Contact;
  @Input() modalRef: BsModalRef;
  @Input() isModal = false;
  @Input() invoice: Invoice;
  @Output() newCard = new EventEmitter<PayMethod>();
  @Output() invoicePaid = new EventEmitter<boolean>();
  @ViewChild('cardElement') cardElement: TemplateRef<any>;
  @ViewChild('paymentFailedWarningModal') paymentFailedWarning: TemplateRef<any>;

  private subscription: Subscription = new Subscription();
  paymentNewForm: FormGroup;
  paymentGateway = 'monri';
  paymentInstance;
  paymentMethod;
  faSpinner = faSpinner;
  loading = {
    order: false,
    gateway: false
  };
  flow = 'components';
  paymentConfigData: PaymentConfigData;
  user: Client;
  maestro = false;
  submitted = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string,
    private renderer: Renderer2,
    private scripts: ScriptService,
    private service: PaymentService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    private auth: AuthenticationService,
    private modalService: BsModalService
  ) {
    this.createFormGroup();
  }

  ngOnInit() {
    this.paymentNewForm.patchValue({billingcid: this.contact !== undefined ? this.contact.id : 'billing'});
    this.subscription.add(
      this.auth.currentUser
        .subscribe(user => {
          if (user) {
            this.user = user;
            this.paymentNewForm.patchValue({clientid: user.id});
            this.loadClientSecret();
          } else {
            this.alert.error($localize`Korisnik nije pronađen`);
            this.router.navigate(['/']);
            return;
          }
        })
    );
  }

  ngAfterViewInit() {
    // if (this.cardElement) {
    //  this.loadGatewayForm();
    // }
  }

  loadClientSecret() {
    if (this.isModal) {
      this.subscription.add(
        // this.service.getPaymentConfigData(this.paymentGateway)
        this.service.getClientSecret(this.user.currencyCode,
          this.invoice.totalraw, 'purchase', 'charge', this.paymentGateway, this.invoice.invoiceid)
          .subscribe(paymentConfigData => {
            if (paymentConfigData) {
              this.paymentConfigData = paymentConfigData;
              this.loadGatewayForm();
            } else {
              this.router.navigate(['/']);
              return;
            }
          })
      );
    } else {
      this.subscription.add(
        this.service.getClientSecret(this.user.currencyCode, 1, 'authorize', 'add_payment_method', this.paymentGateway)
          .subscribe(paymentConfigData => {
            if (paymentConfigData) {
              this.paymentConfigData = paymentConfigData;
              this.loadGatewayForm();
            } else {
              this.router.navigate(['/']);
              return;
            }
          })
      );
    }
  }

  createFormGroup() {
    this.paymentNewForm = this.formBuilder.group({
      clientid: [null, Validators.required],
      cardnumber: [null],
      cardexpirydate: [null],
      billingcid: ['billing', Validators.required],
      description: [null, [Validators.minLength(3), Validators.maxLength(50)]],
      cardtype: [null],
      gateway: [this.paymentGateway, Validators.required],
      remotetoken: [null]
    });
  }

  get f() {
    return this.paymentNewForm.controls;
  }

  loadGatewayForm() {
    if (!this.cardElement || !this.paymentConfigData) {
      return;
    }
    this.loading.gateway = true;
    this.scripts.loadScript(this.paymentGateway, this.renderer)
      .then(data => {
        this.loading.gateway = false;
        if (this.paymentGateway === 'monri') {
          if (this.flow === 'components' && this.cardElement !== undefined) {
            const locale = this.localeId;
            // @ts-ignore
            this.paymentInstance = Monri(this.paymentConfigData.authenticityToken, {locale});
            const components = this.paymentInstance.components({clientSecret: this.paymentConfigData.randomToken});
            // Create an instance of the card Component.
            //   tokenizePan: true,
            let options = {};
            if (this.isModal) {
              options = {
                tokenizePanOffered: true
              };
            } else {
              options = {
                tokenizePan: true
              };
            }
            this.paymentMethod = components.create('card', options);
            // Add an instance of the card Component into the `card-element` <div>.
            this.paymentMethod.mount('card-element');

            this.paymentMethod.addChangeListener('card_number', e => {
              this.maestro = e.data.brand === 'maestro' || e.data.brand === 'diners';
              this.paymentNewForm.patchValue({cardtype: e.data.brand});
            });

            this.paymentMethod.addChangeListener('expiry_date', e => {
              if (e.valid) {
                let cardexpirydate = e.data.month < 10 ? '0' : '';
                cardexpirydate += e.data.month.toString() + e.data.year.toString().slice(-2);
                this.paymentNewForm.patchValue({cardexpirydate});
              } else {
                this.paymentNewForm.patchValue({cardexpirydate: null});
              }
            });

            this.paymentMethod.onChange(event => {
              const displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.textContent = event.error.message;
              } else {
                displayError.textContent = '';
              }
            });
          }
        }
      })
      .catch(error => console.log(error));
  }

  submitForm() {
    if (this.paymentNewForm.invalid) {
      return;
    }
    this.loading.order = true;
    if (this.paymentGateway === 'monri') {
      if (this.flow === 'components') {
        const transactionParams = {
          address: this.user.address1,
          fullName: `${this.user.firstname} ${this.user.lastname}`,
          city: this.user.city,
          zip: this.user.postcode,
          phone: this.user.phonenumber,
          country: this.user.countrycode,
          email: this.user.email,
          orderInfo: 'Autorizacija/Naplata'
        }
        if (!this.isModal && this.maestro) {
          this.alert.error($localize`Maestro i Diners kartice ne mogu se tokenizirati i spremiti u naš sustav`);
          return;
        }
        this.paymentInstance.confirmPayment(this.paymentMethod, transactionParams)
          .then(result => {
            if (result.error) {
              // Inform the customer that there was an error.
              const errorElement = document.getElementById('card-errors');
              errorElement.textContent = result.error.message;
            } else {
              this.submitFormToApi(result.result);
            }
          })
      }
    }
    return false;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  submitFormToApi(result) {
    if (result.status !== 'approved') {
      this.alert.error($localize`Transakcija je odbijena`)
    }
    let type = null;
    let transactionId = null;
    const token = (typeof result.pan_token !== 'undefined') ? result.pan_token : null;
    if (!token) {
      this.maestro = true;
      type = this.paymentNewForm.value.cardtype;
      transactionId = result.order_number;
    } else {
      // this.alert.error('Something went wrong');
      const cardData = (result.payment_method && result.payment_method.data !== undefined) ? result.payment_method.data : null;
      if (!cardData) {
        this.alert.error($localize`Nešto je pošlo krivo`);
        return;
      }
      type = token && cardData.brand !== undefined ? cardData.brand : null;
      this.maestro = type === 'maestro' || type === 'diners';
      if (!this.isModal && this.maestro) {
        this.alert.error($localize`Maestro i Diners kartice ne mogu se tokenizirati i spremiti u naš sustav`);
        return;
      }
      const maskedPan = token && cardData.masked !== undefined ? cardData.masked.toString() : null;
      const splitPan = maskedPan.split('-');
      const lastFour = splitPan[splitPan.length - 1];
      transactionId = result.pan_token;
      this.paymentNewForm.patchValue({cardnumber: lastFour});
      this.paymentNewForm.patchValue({cardtype: type});
      this.paymentNewForm.patchValue({remotetoken: token});
    }
    this.paymentNewForm.patchValue({gateway: this.paymentGateway});

    this.loading.order = true;
    if (this.maestro) {
      const paymentMethod = this.invoice && this.invoice.paymentmethod !== undefined ? this.invoice.paymentmethod : null;
      if (result.status.toLowerCase() !== 'approved') {
        function delay(ms: number) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }

        (async () => {
          this.modalRef.hide();
          this.loading.order = false;
          this.invoicePaid.emit(false);
          this.openModal(this.paymentFailedWarning);
          await delay(3000);
          location.reload();
        })();

      } else {
        this.subscription.add(
          this.invoiceService
            .addInvoicePayment(this.invoice, transactionId, null,
              paymentMethod, null, null, false, type)
            .pipe(take(1))
            .subscribe(res => {
              this.loading.order = false;
              if (res) {
                this.alert.success($localize`Uplata je uspješno dodana na ponudu`);
                this.modalRef.hide();
                this.invoicePaid.emit(true);
              } else {
                this.alert.error($localize`Nešto je pošlo krivo kod dodavanja uplate`);
              }
              location.reload();
            })
        );
      }
    } else {
      this.subscription.add(
        this.service.addRemoteToken(this.paymentNewForm.value)
          .pipe(take(1))
          .subscribe(res => {
            this.loading.order = false;
            if (res !== null) {
              this.alert.success($localize`Kartica je uspješno dodana`);
              if (!this.isModal) {
                // this.router.navigate([`/profile/cards`]);
                if (this.modalRef) {
                  this.modalRef.hide();
                }
              } else {
                // If it is from modal load new card and emit it to parent component
                // but also add transaction id if it exists
                if (result.order_number !== undefined) {
                  const payMethod = res.paymethod;
                  payMethod.transaction_id = result.order_number;
                }
                this.newCard.emit(res.paymethod);
                if (this.modalRef) {
                  this.modalRef.hide();
                }
              }
              // location.reload();
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.paymentInstance = undefined;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
