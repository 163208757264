import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {SystemService} from '../../../_services/system.service';
import {Credit} from '../../../_models/credit';
import {Pager} from '../../../support/tickets/pager';
import {Currency} from '../../../_models/currency';
import {AuthenticationService} from '../../../_services';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-credit-transactions',
  templateUrl: './credit-transactions.component.html'
})
export class CreditTransactionsComponent implements OnInit, OnDestroy {
  @Input() modalRef: BsModalRef;
  private subscription: Subscription = new Subscription();
  userCreditTransactions: Credit[];
  currentCurrency: Currency;
  pageSize = 15;
  pager: Pager;
  loading = {
    transactions: false
  };
  public displayHrk = false;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private systemService: SystemService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.currentCurrency = this.authenticationService.currentCurrencyValue;
  }

  ngOnInit(): void {
    this.displayHrk = this.config.displayHrk;
    this.loadCreditTransactions();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadCreditTransactions() {
    if (this.userCreditTransactions !== undefined) {
      return false;
    }
    const crTranSub = this.route.queryParamMap.pipe(
      switchMap((params) => {
        this.loading.transactions = true;
        const currentPage = (params.has('page')) ? parseInt(params.get('page'), 10) : 1;
        return this.systemService.getUsersCredits(currentPage, this.pageSize);
      }))
      .subscribe((res) => {
        this.loading.transactions = false;
        this.pager = res.pager;
        this.userCreditTransactions = res.userCredits;
      });
    this.subscription.add(crTranSub);
  }

  closeModal() {
    this.router.navigate(
      ['.'],
      {relativeTo: this.route, queryParams: {}});
    this.modalRef.hide();
  }

  formatDate(d: Date) {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  displayDateMessage(dateString: string): string {
    const dateNumber = Date.parse(dateString);
    if (dateNumber) {
      return this.formatDate(new Date(dateNumber));
    } else if (dateString.indexOf('<b>Today</b>') > -1) {
      return this.formatDate(new Date());
    } else {
      return null;
    }
  }
}
