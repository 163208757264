import { NgModule, InjectionToken } from "@angular/core";
import { environment } from "../environments/environment";

export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export class AppConfig {
  appRoot: string;
  defaultLocale: string;
  apiEndpoint: string;
  wpEndpoint: string;
  recaptchaKey: string;
  faqLink: string;
  wpLink: string;
  affiliateFaqLink: string;
  vncEndpoint: string;
  freeDomainLink: string;
  productSets: any;
  languageList: any;
  productSetsCartView: any;
  tos: string;
  contact: string;
  paypalClientId: string;
  openReplay: IOpenReplayConfig;
  displayHrk: boolean;
}

export interface IOpenReplayConfig {
  start: boolean;
  projectKey: string;
  ingestPoint: string;
  __DISABLE_SECURE_MODE: boolean;
  obscureTextNumbers: boolean;
  obscureTextEmails: boolean; // Obscure emails in text elements. Emails will be converted to a random chain of asterisks. Default: true.
  obscureInputEmails: boolean; // Obscure emails in input fields. Email values will be converted to a random chain of asterisks. Default: true.
  obscureInputDates: boolean; // Obscure dates in input fields. Date values will be converted to a random chain of asterisks. Default: false.
  defaultInputMode: any; // 0 | 1 | 2 Default capture mode for input values. Respectively: plain, obscured or ignored. Default: 1 (obscured).
}

export const APP_DI_CONFIG: AppConfig = {
  appRoot: environment.appRoot,
  defaultLocale: environment.defaultLocale,
  apiEndpoint: environment.apiEndpoint,
  wpEndpoint: environment.wpEndpoint,
  vncEndpoint: environment.vncEndpoint,
  recaptchaKey: environment.recaptchaKey,
  faqLink: environment.faqLink,
  wpLink: environment.wpLink,
  affiliateFaqLink: environment.affiliateFaqLink,
  freeDomainLink: environment.freeDomainLink,
  productSets: environment.productSets,
  languageList: environment.languageList,
  productSetsCartView: environment.productSetsCartView,
  tos: environment.tos,
  contact: environment.contact,
  paypalClientId: environment.paypalClientId,
  openReplay: {
    start: environment.openReplay.start,
    projectKey: environment.openReplay.projectKey,
    ingestPoint: environment.openReplay.ingestPoint,
    __DISABLE_SECURE_MODE: environment.openReplay.__DISABLE_SECURE_MODE,
    obscureTextNumbers: environment.openReplay.obscureTextNumbers,
    obscureTextEmails: environment.openReplay.obscureTextEmails,
    obscureInputEmails: environment.openReplay.obscureInputEmails, // Obscure emails in input fields. Email values will be converted to a random chain of asterisks. Default: true.
    obscureInputDates: environment.openReplay.obscureInputDates, // Obscure dates in input fields. Date values will be converted to a random chain of asterisks. Default: false.
    defaultInputMode: environment.openReplay.defaultInputMode, //0 | 1 | 2 Default capture mode for input values. Respectively: plain, obscured or ignored. Default: 1 (obscured).
  },
  displayHrk: environment.displayHrk,
};

@NgModule({
  providers: [
    {
      provide: APP_CONFIG,
      useValue: APP_DI_CONFIG,
    },
  ],
})
export class AppConfigModule {}
