import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';

import {IpService} from './ip.service';
import {AlertService} from '../_services';

@Component({
  templateUrl: 'unblock-ip.component.html'
})
export class UnblockIpComponent implements OnDestroy{
  private subscription: Subscription = new Subscription();
  ip: string;
  unblockForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: IpService,
    private alert: AlertService
  ) {
    this.unblockForm = this.formBuilder.group({
      ip: [null, [Validators.required, Validators.pattern('^(?!0)(?!.*\\.$)((1?\\d?\\d|25[0-5]|2[0-4]\\d)(\\.|$)){4}$')]]
    });
  }

  get f() { return this.unblockForm.controls; }

  unblockIp() {
    this.submitted = true;
    if (this.unblockForm.invalid) {
      return;
    }

    this.loading = true;
    const unblockSub = this.service.unblockIp(this.unblockForm.get('ip').value)
      .subscribe(res => {
        this.loading = false;
        if (res !== null && typeof res.result !== 'undefined' && res.result === 'success') {
          const message = (typeof res.message !== 'undefined') ? res.message : 'Success';
          this.alert.success(message);
        } else {
          const message = (res !== null && typeof res.message !== 'undefined') ? res.message : 'Error';
          this.alert.error(message);
        }
      });
    this.subscription.add(unblockSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
