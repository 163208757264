<div class="d-flex justify-content-between align-items-center bd-bottom p-3 bg-white flex-column flex-lg-row">
  <h3 class="font-weight-bold mb-2 mb-lg-0 text-color-2" i18n>Kontakti</h3>
  <div>
    <span class="mr-3" i18n>Filter:</span>
    <select class="rounded-light width-170 mr-3 mb-3 mb-lg-0" (change)="searchContact($event.target.value)">
      <option value="all" i18n>Sve</option>
    </select>
    <a routerLink="/profile/contact/new" class="btn btn-bordered rounded-light btn-blue btn-sm"  i18n>Novi kontakt</a>
  </div>
</div>

<div class="p-3">
  <div class="container-fluid">
    <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="contacts?.length">
      <div class="col-1">ID</div>
      <div class="col-3" i18n>Ime i prezime / Naziv tvrtke</div>
      <div class="col-4" i18n>Email</div>
      <div class="col-3" i18n>Status</div>
      <div class="col-1" i18n>Alati</div>
    </div>

    <ng-container *ngIf="contacts?.length">
        <div class="row bg-white bd-bottom p-2" *ngFor="let contact of contacts">
          <div class="col-4 mb-2 font-weight-bold d-lg-none">ID</div>
          <div class="col-8 mb-2 col-lg-1 mb-lg-0"><a [routerLink]="[contact.id]">{{ contact.id }}</a></div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Ime i prezime</div>
          <div class="col-8 mb-2 col-lg-3 mb-lg-0">
            <span *ngIf="contact.companyname.length">{{ contact.companyname }}</span>
            <span *ngIf="!contact.companyname || contact.companyname.length === 0">{{ contact.firstname }} {{contact.lastname}} </span>
          </div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Email</div>
          <div class="col-8 mb-2 col-lg-4 mb-lg-0"><a [routerLink]="[contact.id]">{{ contact.email }}</a></div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Status</div>
          <div class="col-8 mb-2 col-lg-3 mb-lg-0"><span *ngIf="contact.subaccount === 0" i18n>Kontakt</span><span *ngIf="contact.subaccount === 1" i18n>Podračun</span></div>

          <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Alati</div>
          <div class="col-8 mb-2 col-lg-1 mb-lg-0">
              <svg-icon src="assets/svg/delete.svg" class="icon icon-20 mr-sm icon-language" (click)="deleteModal(userdelete)"></svg-icon>
          </div>

          <ng-template #userdelete>
            <div class="modal-header">
              <h3 i18n>Brisanje kontakta</h3>
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p i18n>Sigurno želiš obrisati kontakt?</p>
              <p class="font-weight-bold">
                <span *ngIf="contact.companyname.length">{{ contact.companyname }}</span>
                <span *ngIf="!contact.companyname || contact.companyname.length === 0">{{ contact.firstname }} {{contact.lastname}} </span>
              </p>
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <button type="button" class="btn rounded-light" aria-label="Close" (click)="decline()" i18n>Odustani</button>
              <button type="button" class="btn rounded-light btn-danger" aria-label="Close" (click)="confirm(contact)" i18n>Da</button>
            </div>
          </ng-template>
        </div>
    </ng-container>

    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!contacts?.length && !loading" i18n>Nema dodanih korisnika</div>
  </div>
</div>
