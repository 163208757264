import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {Invoice} from '../invoice';
import {InvoiceService} from '../invoice.service';
import {Pager} from '../../../support/tickets/pager';
import {Currency} from '../../../_models/currency';
import {AuthenticationService} from '../../../_services';

@Component({
  selector: 'app-bill-list',
  templateUrl: './bills-list.component.html'
})
export class BillsListComponent implements OnInit, OnDestroy {
  @Input() status = 'all';
  @Input() template = 'list';
  @Input() loading = false;
  @Input() invoices: Invoice[];
  private subscription: Subscription = new Subscription();
  pager: Pager;
  pageSize = 20;
  order = 'desc';
  orderBy = 'id';
  currentCurrency: Currency;
  statusTranslated: string;

  constructor(
    private service: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.currentCurrency = this.authenticationService.currentCurrencyValue;
  }

  ngOnInit() {
    if (this.template !== 'unpaid') {
      this.loading = true;
      const invoiceListSubs = this.route.queryParams.pipe(
        switchMap(params => {
          const data = {
            order: (typeof params !== 'undefined' && params.order !== undefined) ? params.order : this.order,
            orderby: (typeof params !== 'undefined' && params.orderBy !== undefined) ? params.orderBy : this.orderBy,
            page: (typeof params !== 'undefined' && params.page !== undefined) ? params.page : 1,
            status: (typeof params !== 'undefined' && params.status !== undefined) ? params.status : this.status
          };
          this.status = data.status;
          this.statusTranslated = InvoiceService.getBillStatusLabelByStatusKey(data.status);
          return this.service.getList(data, this.pageSize);
        }),
      ).subscribe(res => {
        this.loading = false;
        this.pager = res.pager;
        this.invoices = res.invoices;
      });
      this.subscription.add(invoiceListSubs);
    }
  }

  getInvoiceStatus(invoice: Invoice) {
    switch (invoice.status.toLowerCase()) {
      case 'unpaid':
        return 'text-danger';
      case 'paid':
        return 'text-success';
      case 'cancelled':
        return 'text-warning';
    }
  }

  getInvoiceSum() {
    let total = 0;
    this.invoices.forEach(invoice => {
      total += parseFloat(String(invoice.total));
    });
    return total;
  }

  showFilterIcon(element: string, direction: string) {
    if (this.orderBy === element) {
      return direction === this.order;
    }
    return true;
  }

  sortBills(sortElement: string) {
    this.orderBy = sortElement;
    if (this.orderBy === sortElement) {
      this.order = (this.order === 'asc') ? 'desc' : 'asc';
    }
    this.loading = true;
    const invoices = this.service.getList({
      orderby: this.orderBy,
      order: this.order,
      status: this.status
      }, this.pageSize)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading = false;
        this.pager = res.pager;
        this.invoices = res.invoices;
      });
    this.subscription.add(invoices);
  }

  searchBillsByStatus(value: string, noRoute: boolean = false) {
    if (noRoute) {
      this.statusTranslated = InvoiceService.getBillStatusLabelByStatusKey(value);
      this.loading = true;
      this.status = value;
      const invoices = this.service.getList({
        orderby: this.orderBy,
        order: this.order,
        status: this.status
      }, this.pageSize)
        .pipe(take(1))
        .subscribe((res) => {
          this.loading = false;
          this.pager = res.pager;
          this.invoices = res.invoices;
        });
      this.subscription.add(invoices);
    } else {
      this.status = value;
      this.router.navigate(['/accounting/bills'], {queryParams: {status: this.status}});
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
