import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Product} from '../_models/product';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {DiscountPost} from '../_models/discount-post';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class WpService {
  private readonly handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('WPService');
  }

  private httpBuildQuery(params) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }

    let query = '?';
    let index = 0;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        index++;
        const param = key;
        const value = params[key];
        if (index === 1) {
          query += param + '=' + value;
        } else {
          query += '&' + param + '=' + value;
        }
      }
    }

    return query;
  };

  public getDiscountPost(lang: string): Observable<DiscountPost[]> {
    const queryParams = {
      post_type: 'post',
      meta_key: 'is_action',
      meta_value: 1,
      lang
    };

    return this.http.get<HttpResponse<DiscountPost[]>>
    (`${this.config.wpEndpoint}/knox/discounts${this.httpBuildQuery(queryParams)}`, httpOptions)
      .pipe(
        map((res) => {
          const now = new Date();
          let discounts: DiscountPost[] = (res.body !== undefined) ? res.body : [];
          discounts = discounts.filter(x => {
            const validTo = new Date(x.actionValidTo);
            return validTo > now;
          });
          return discounts;
        }),
        catchError(this.handleError('getDiscountPost', null))
      );
  }
}
