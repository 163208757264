<div class="row mw-100 pt-1 pt-md-5 bg-background">
  <div class="col-10 offset-1 col-lg-8 offset-lg-2 col-xl-6 offset-lg-3 box p-2 p-md-3 p-lg-4">
    <form [formGroup]="parentForm" (ngSubmit)="onSubmit()">
      <app-profile-details [submitted]="submitted"
                           [edit]="true"
                           [register]="true"
                           [parentForm]="parentForm" class="mb30"></app-profile-details>

      <div class="form-row pl-3 pr-3">
        <div class="form-group col-12 col-lg-8">
          <label for="password" class="col-form-label" i18n>Lozinka</label>
          <div class="input-group">
            <input id="password" type="password" autocomplete="off" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [type]="!shown ? 'password' : 'text'" />
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput()">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown"></fa-icon>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback w-100">
              <div *ngIf="f.password.errors.required" i18n>Moraš unijeti lozinku</div>
              <div *ngIf="f.password.errors.minlength" i18n>Lozinka mora sadržavati najmanje 8 znakova</div>
              <div *ngIf="f.password.errors.maxlength" i18n>Lozinka mora sadržavati najviše 64 znaka</div>
              <div *ngIf="f.password.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo i jedan broj</div>
            </div>
          </div>
          <password-strength-meter [password]="f.password.value"></password-strength-meter>
        </div>
        <div class="form-group col-12 col-lg-4">
          <label class="col-form-label icon icon-35"></label>
          <div class="input-group">
            <button class="btn rounded-light btn-outline-info" type="button" (click)="generateRootPassword()" i18n>Kreiraj novu</button>
          </div>
        </div>
      </div>

      <div class="pl-3 pr-3">

        <div class="font-italic mb-3">
          <p class="mb-3" i18n>Jačina lozinke</p>
          <p i18n>Lozinka mora sadržavati 8-64 znaka</p>
          <p><strong i18n>Savjeti za sigurnu lozinku:</strong></p>
          <p i18n>Koristi velika i mala slova</p>
          <p i18n>Uključi barem jedan poseban znak (# $ ! % & itd...)</p>
          <p i18n>Izbjegavaj riječi iz rječnika</p>
        </div>
        
        <input type="hidden" formControlName="recaptcha" class="form-control" />

        <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback w-100">
          <div *ngIf="f.recaptcha.errors.required" i18n>Recaptcha invalid</div>
        </div>

        <hr>
        <label class="form-check-label"><span i18n>Molimo te da prije dovršetka pažljivo pročitaš naše</span>&nbsp;<a [href]="getTosLink()" target="_blank" i18n>Uvjete korištenja usluga</a>&nbsp;<span i18n>i obveze koje iz njih proizlaze.</span></label>
        <label for="tos" class="form-check-label mb-3"><span i18n>Ukoliko imaš pitanja vezanih uz same Uvjete, molimo te da se</span>&nbsp;<a [href]="getContactLink()" target="_blank" i18n>obratiš našoj podršci</a>&nbsp;<span i18n>koja će ti odgovoriti na sva pitanja koja te zanimaju u vezi Uvjeta korištenja usluga.</span></label>
        <div class="form-check mt-3">
          <input id="tos" type="checkbox" formControlName="tos" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.tos.errors }" />
          <label for="tos" class="form-check-label"><span i18n>Prihvaćam </span>&nbsp;<a [href]="getTosLink()" target="_blank" i18n>Uvjete korištenja usluga</a></label>
          <div *ngIf="submitted && f.tos.errors" class="invalid-feedback w-100">
            <div *ngIf="f.tos.errors.required" i18n>Potrebno je složiti se s Uvjetima korištenja</div>
          </div>
        </div>
      </div>

      <hr>
      <div class="container-fluid">
        <div class="row">
          <app-loader *ngIf="loading"></app-loader>
          <button [disabled]="loading" type="submit" class="order-1 order-md-2 col-sm-12 col-md-4 offset-md-4 mb-3 mb-md-0 btn btn-blue rounded-light" i18n>Registriraj se</button>
          <a routerLink="/" class="order-2 order-md-1 col-sm-12 col-md-4 btn rounded-light btn-bordered" i18n>Odustani</a>          
        </div>
      </div>
    </form>
  </div>
</div>
