import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {NgSelectModule} from '@ng-select/ng-select';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';

import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from './profile.component';
import {ProfileEditComponent} from './edit';
import {ProfilePasswordComponent} from './password';
import {ProfileDetailsComponent} from './details';
import {LoaderModule} from '../loader/loader.module';
import {ProfileCreditComponent} from './credit';
import {ContactNewComponent} from './contacts/new';
import {ContactEditComponent} from './contacts/edit';
import {ContactListComponent} from './contacts/list';
import {ContactDetailsComponent} from './contacts/details';
import {ProfileAffiliateComponent} from './affiliate';
import {ExternalUrlModule} from '../shared/external-url';
import {SupportModule} from '../support';
import {ContactPermissionsComponent} from './contacts/permissions';
import {CreditTransactionsComponent} from './credit/credit-transactions/credit-transactions.component';
import {CardListComponent} from './cards/card-list';
import {CardNewComponent} from './cards/new';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    LoaderModule,
    ProfileRoutingModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxIntlTelInputModule,
    AngularSvgIconModule,
    ExternalUrlModule,
    SupportModule,
    AccordionModule,
    TooltipModule,
    FontAwesomeModule,
    PasswordStrengthMeterModule
  ],
  declarations: [
    ContactNewComponent,
    ContactEditComponent,
    ContactListComponent,
    ProfileCreditComponent,
    ProfileEditComponent,
    ProfilePasswordComponent,
    ProfileDetailsComponent,
    ProfileComponent,
    ContactDetailsComponent,
    ProfileAffiliateComponent,
    ContactPermissionsComponent,
    CreditTransactionsComponent,
    CardListComponent,
    CardNewComponent
  ],
  exports: [
    ProfileDetailsComponent,
    ContactNewComponent,
    CardListComponent,
  ]
})
export class ProfileModule {}
