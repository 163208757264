<div class="modal-header d-flex justify-content-between">
  <div class="d-flex align-items-center">
    <svg-icon src="assets/svg/Tasks.svg" class="icon icon-50 mr-2"></svg-icon>
    <h3 i18n>Uplate/isplate kredita</h3>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="userCreditTransactions?.length">
      <div class="col-2" i18n>Datum</div>
      <div class="col-8" i18n>Opis</div>
      <div class="col-2 text-right" i18n>Iznos</div>
    </div>

    <ng-container *ngIf="userCreditTransactions?.length">
      <div class="row bd-bottom p-2" [class.mb-3]="last" *ngFor="let creditTransaction of userCreditTransactions; let last = last;">
        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum</div>
        <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{this.displayDateMessage(creditTransaction.date) | date:'shortDate' }}</div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Opis</div>
        <div class="col-8 mb-2 col-lg-8 mb-lg-0">{{creditTransaction.description}}</div>

        <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Iznos</div>
        <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-right">{{creditTransaction.amount | currency : currentCurrency.code : 'symbol-narrow'}}
          <br><span *ngIf="currentCurrency.code === 'EUR' && displayHrk">({{creditTransaction.amount*7.53450 | number: '1.2-2'}} HRK)</span>
        </div>
      </div>
      <app-pager [pager]="pager" *ngIf="userCreditTransactions?.length"></app-pager>
    </ng-container>

    <app-loader *ngIf="loading.transactions"></app-loader>

  </div>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-primary rounded-light" (click)="closeModal()" i18n>Zatvori</button>
</div>
