export class DomainWhois {
  name: string;
  email: string;
  company: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  telCountryCode: string;
  phone: string;
  status: string;
  telephoneNumber: any;
  whoisString: string;
  zipCode: string;
  clientId: number;
}
