/* tslint:disable:variable-name */
export class Client {
  id: number;
  affiliateid: number;
  userid: number;
  token: string;
  role: string;
  firstname: string;
  lastname: string;
  companyname: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  countrycode: string;
  phonenumber: string;
  telephoneNumber: any;
  phonenumberformatted: string;
  tax1_name: string;
  tax1_value: number;
  tax2_name: string;
  tax2_value: number;
  tax_id: string;
  taxexempt: boolean;
  password: string;
  securityqid: number;
  credit: number;
  securityqans: string;
  cardtype: string;
  cardnum: string;
  expdate: string;
  startpdate: string;
  issuenumber: string;
  cvv: string;
  currency: number;
  currencyCode: string;
  groupid: number;
  customfields: any;
  language: string;
  clientip: string;
  notes: string;
  marketingoptin: boolean;
  noemail: boolean;
  skipvalidation: boolean;
  action: string;
  qrlink: string;
  twofa: boolean;
  countryname: string;
  persontype: string;
  permissions: Array<string>;
  subaccount: number;
  tos: boolean;
  forbiddenFields: Array<string>;
  customfields1: string; // Represents the e-bill opt-in and can be either 'on' or ''
  customfields2: string; // Represents the e-bill email address
}
