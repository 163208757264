<ng-container *ngIf="orderItems?.length || domainItems?.length">
  <div class="dropdown-item" *ngFor="let item of orderItems">
    <div class="row align-items-center">
      <ng-container *ngIf="item.canEdit; else cantEditName">
        <a
          class="col-7 color-shadow"
          routerLink="cart/product"
          [queryParams]="{ pid: item.pid, gid: item.gid, cid: item.cartId }"
        >
          {{ item.name }}
        </a>
      </ng-container>
      <ng-template #cantEditName>
        <span class="col-7">{{ item.name }}</span>
      </ng-template>
      <span class="col-4 text-right color-white">
        {{ cartCurrency?.prefix }}

        <!-- Check if there are discounts available -->
        <ng-container
          *ngIf="
            item.totalPriceDiscount &&
            item.totalPriceDiscount !== item.totalPrice
          "
        >
          <span>
            {{ item.totalPriceDiscount | number : "1.2-2" }}
          </span>
          <span class="text-danger line-through font-italic">
            {{ item.totalPrice | number : "1.2-2" }}
          </span>
        </ng-container>

        <!-- If there are not discounts available, show regular price -->
        <ng-container
          *ngIf="
            !item.totalPriceDiscount ||
            item.totalPriceDiscount === item.totalPrice
          "
        >
          {{ item.totalPrice | number : "1.2-2" }}
        </ng-container>

        &nbsp;{{ cartCurrency?.suffix }}
      </span>

      <button
        class="col-1 btn btn-link color-white p-0"
        tooltip="Ukloni iz košarice"
        i18n-tooltip="Ukloni iz košarice"
        (click)="removeItem(item)"
      >
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
    </div>
  </div>

  <ng-container *ngFor="let domain of domainItems">
    <div class="dropdown-item" *ngIf="domain.cartProductId === undefined">
      <div class="row align-items-center">
        <span class="col-7">{{ domain.name }}</span>
        <span class="col-4 text-right color-white">
          {{ cartCurrency?.prefix }}
          <span> {{ domain.price | number : "1.2-2" }} </span>&nbsp;
          {{ cartCurrency?.suffix }}
        </span>
        <button
          class="col-1 btn btn-link color-white p-0"
          tooltip="Ukloni iz košarice"
          i18n-tooltip="Ukloni iz košarice"
          (click)="removeDomain(domain)"
        >
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="dropdown-divider"></div>

  <div class="dropdown-item d-flex justify-content-between">
    <div>
      <a
        routerLink="cart/overview"
        tooltip="Idi u košaricu"
        i18n-tooltip="Idi u košaricu"
        *ngIf="orderItems?.length > 0 || domainItems?.length > 0"
        i18n
      >
        Idi u košaricu
      </a>
    </div>
    <div>
      <span class="mr-1" i18n>Sveukupno:</span>
      <span>{{ total | currency : cartCurrency.code : "symbol-narrow" }}</span>
    </div>
  </div>
</ng-container>
