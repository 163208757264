import {Inject, Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {HttpCacheService} from '../_services/http-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private cacheService: HttpCacheService,
      @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cacheRequest = false;
    if (req.body !== null) {
      if (req.body instanceof FormData) {
        cacheRequest = (req.body.get('cache') !== undefined && (req.url.indexOf(this.config.apiEndpoint) >= 0));
      } else {
        cacheRequest = (typeof req.body.cache !== 'undefined' && (req.url.indexOf(this.config.apiEndpoint) >= 0));
      }
    }
    /*pass along non-cacheable requests*/
    if (!cacheRequest) {
      return next.handle(req);
    }

    let cacheKey = '';
    const params =  Object.keys(req.body)
      .filter(prop => !(prop === 'cache' || prop === 'action'))
      .reduce((obj, key) => {
        obj[key] = req.body[key];
        return obj;
      }, {});
    cacheKey = Object.keys(params).map(key => key + '=' + req.body[key]).join('&');
    cacheKey = `${req.url}/${req.body.action}?${cacheKey}`;
    let cache = req.body.cache;
    if (req.body instanceof FormData) {
     cache = req.body.get('cache');
     cache = JSON.parse(cache);
    }

    /*invalidate cache*/
    if (!cache) {
      let action = req.body.action;
      let keysToInvalidate = (typeof req.body.invalidateKeys !== 'undefined') ? req.body.invalidateKeys : [];
      if (req.body instanceof FormData) {
        action = req.body.get('action');
        keysToInvalidate = req.body.get('invalidateKeys');
        keysToInvalidate = JSON.parse(keysToInvalidate);
      }
      this.cacheService.invalidateCache(action, keysToInvalidate);
      return next.handle(req);
    }

    /*attempt to retrieve a cached response*/
    const cachedResponse: HttpResponse<any> = this.cacheService.get(cacheKey);
    /*return cached response*/
    if (cachedResponse) {
      return of(cachedResponse);
    }

    /*send request to server and add response to cache*/
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cacheService.put(cacheKey, event);
          }
        })
      );
  }
}
