<div *ngIf="!isIncluded" class="d-flex align-items-center p-3 p-tb-sm mb-3" [ngClass]="{
'bg-color1': !isIncluded, 'bg-color2': isIncluded
}">
  <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
     class="color-white btn btn-outline-light rounded-light mr-3"><</a>
  <h3 class="color-white" i18n>Odabir domene</h3>
</div>

<div class="container-fluid mb-3">
  <div [formGroup]="searchForm" class="row">
    <div class="col-sm-12 col-md-9 col-xl-10 d-flex p-0 mb-3 mb-md-0 bg-text">
      <label for="search-box" class="col-2 col-lg-1 input-append pl-3 pr-3 m-0 align-middle pt-2 text-white font-weight-normal">www.</label>
      <input id="search-box" placeholder="Pretraži domene" i18n-placeholder="Pretraži domene" formControlName="search" class="height-40 col-10 col-lg-11 bd-grey rounded-0"
             (keyup.enter)="search($event, false)" [ngClass]="{ 'is-invalid': submitted && f.search.errors }"/>
      <div *ngIf="submitted && f.search.errors" class="invalid-feedback w-100">
        <div *ngIf="f.search.errors.required" i18n>Ime domene je obavezno polje</div>
        <div *ngIf="f.search.errors.minlength" i18n>Ime domene mora sadržavati najmanje 2 znaka</div>
        <div *ngIf="f.search.errors.pattern" i18n>Samo alfanumerički znakovi, - i _</div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-xl-2 p-0">
      <button class="btn btn-color2 height-40 w-100 rounded-0" type="submit"
              (click)="search($event, false)"
              [disabled]="f.search.errors"
              *ngIf="!loading">
          <span class="icon icon-20 icon-search mr-2"></span><span i18n>Pretraži</span>
      </button>
    </div>
    <app-loader *ngIf="loading"></app-loader>
  </div>
</div>

<div class="container-fluid mb-5" *ngIf="searchAction && domainPricingList?.length; else pricing">
  <div class="row text-lg-center bg-grey bdt-color2 d-none d-lg-flex">
    <div class="col-2 pt-2 pb-2 text-left" i18n>Domena</div>
    <div class="col-2 pt-2 pb-2" i18n>Status</div>
    <div class="col-2 pt-2 pb-2" i18n>ID Zaštita</div>
    <div class="col-3 pt-2 pb-2" i18n>Ciklus plaćanja</div>
    <div class="col-3 pt-2 pb-2" i18n>Košarica</div>
  </div>

  <div class="mb-5">
    <app-product-domain-new *ngFor="let pricing of domainPricingList; let index = index;"
                            [name]="search$"
                            [domainPricing]="pricing"
                            [index]="index"
                            [filter]="filter"
                            [autoAdd]="autoAdd"
                            [cartCurrency]="cartCurrency"></app-product-domain-new>
  </div>

  <div class="row" *ngIf="!isIncluded && addedDomains > 0">
    <p class="col-12 col-lg-6 mb-3 mb-lg-0" i18n>*Cijene su izražene bez PDV-a</p>
    <a routerLink="../options/domain" class="col-12 col-lg-3 offset-lg-3 btn btn-green rounded-light" i18n>Nastavi</a>
  </div>
</div>

<ng-template #pricing>
  <div class="mb-3">
    <span class="fsmaller"  *ngIf="submitted && domainPricingList?.length" i18n>*Cijene su izražene bez PDV-a</span>
  </div>
</ng-template>

