import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, RoleGuard} from '../_guards';
import {CartComponent} from './cart.component';
import {StepProductComponent} from './steps/product';
import {StepOptionsComponent} from './steps/options';
import {StepUserComponent} from './steps/user';
import {StepPaymentComponent} from './steps/payment';
import {StepConfirmationComponent} from './steps/confirmation';
import {StepOverviewComponent} from './steps/overview';
import {StepOptionsDomainsComponent} from './steps/options-domains';

const cartRoutes: Routes = [
  {
    path: '',
    component: CartComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {path: 'product', component: StepProductComponent, data: {step: 1}},
      {path: 'options/domain', component: StepOptionsDomainsComponent, data: {step: 2}},
      {path: 'options/:cartId', component: StepOptionsComponent, data: {step: 2}},
      {path: 'user', component: StepUserComponent, data: {step: 3}},
      {path: 'overview', component: StepOverviewComponent, data: {step: 4}},
      {path: 'payment', component: StepPaymentComponent, data: {step: 5}},
      {path: 'confirmation', component: StepConfirmationComponent, data: {step: 6}}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(cartRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class CartRoutingModule {}
