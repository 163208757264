import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {AuthenticationService} from '../../../_services';
import {ProductService} from '../../../_services/product.service';
import {ProductGroup} from '../../../_models/product-group';
import {ProductConfigOption} from '../../../_models/product-config-option';
import {Currency} from '../../../_models/currency';
import {CartService} from '../../cart.service';

@Component({
  selector: 'app-cart-step-product',
  templateUrl: './step-product.component.html'
})
export class StepProductComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  productGroup: ProductGroup;
  productGroups: ProductGroup[];
  configOptions: ProductConfigOption[] = [];
  productSet: string;
  groupSet: string;
  cartCurrency: Currency;
  loading = {
    groups: false,
    product: false
  };

  constructor(
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    protected route: ActivatedRoute,
    protected router: Router,
    private cartService: CartService
  ) {

    const cartCurrSub = this.cartService.getCartCurrency()
      .subscribe(currency => {
        this.cartCurrency = currency;
      });
    this.subscription.add(cartCurrSub);

    this.loading.groups = true;
    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        const groupId = (typeof params.gid !== 'undefined') ? parseInt(params.gid, 10) : null;
        this.groupSet = (typeof params.groupSet !== 'undefined') ? params.groupSet : null;
        if (groupId === null && this.groupSet === null) {
          this.router.navigate(['/cart']);
          return;
        }
        if (this.groupSet === null || this.groupSet !== 'domain') {
          this.subscription.add(
            this.productService.getProductGroups()
              .pipe(take(1))
              .subscribe(groups => {
                this.loading.groups = false;
                this.productGroups = (this.groupSet !== null) ? this.productService.getProductGroupsBySet(this.groupSet) : null;
                if (this.groupSet === null) {
                  this.productGroup = groups.find(x => x.id === groupId);// : this.productGroups[0];
                } else {
                  this.productGroup = this.productGroups.find(x => x.tag === this.groupSet);
                  if (this.productGroup === undefined) {
                    this.productGroup = this.productGroups[0];
                  }
                }
                if (this.productGroup) {
                  this.productSet = this.productService.getProductSetByTag(this.productGroup.tag)
                }
              })
          );
        } else {
          this.productSet = this.groupSet;
          const newGroup = new ProductGroup();
          newGroup.name = this.groupSet;
          this.productGroup = newGroup;
        }
      });
    this.subscription.add(routerQidSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
