import {Component, Input} from '@angular/core';
import {Pager} from '../tickets/pager';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html'
})
export class PagerComponent {
  @Input() pager: Pager;
}
