<h4 class="text-color-2 bd-bottom p-3 bg-white pl-3 font-weight-bold pl-2" i18n>Početna</h4>

<div class="bg-color2 bdl-blue">
  <div class="container ml-0">
    <div class="d-flex flex-column flex-lg-row pl-3">
      <div class="width-12 width-1-mobile pt-4 pb-4 order-2 order-lg-1">
        <h4 class="color-white mb-3" i18n>Besplatno prenesi svoj hosting paket u MyDataKnox</h4>
        <p class="mb-5" i18n>Tražiš novog providera i želiš prenijeti postojeće stranice, ali trenutni web hosting ti vrijedi još neko vrijeme? Ne brini jer MyDataKnox ti za prijelaz s drugog providera nudi besplatan prijenos stranica i pratećeg sadržaja.</p>
        <div class="d-flex width-1">
          <a *ngIf="getLocale() === 'hr'" href="https://mydataknox.com/hr/web-hosting/standard-web-hosting-paketi#choose_hosting" class="btn btn-sm btn-green rounded bg-color4 mb-3 mr-4" title="Izaberi web hosting" i18n>Saznaj više</a>
          <a *ngIf="getLocale() === 'en'" href="https://mydataknox.com/en/web-hosting/standard-web-hosting#choose_hosting" class="btn btn-sm btn-green rounded bg-color4 mb-3 mr-4" title="Choose a web hosting package" i18n>Saznaj više</a>
        </div>
      </div>
      <div class="width-12 width-1-mobile m0 m0 order-1 order-lg-2">
        <div class="cover-image">
          <div class="divider-before divider-blue"></div>
          <img src="https://mydataknox.com/wp-content/themes/twentysixteen-child/images/web-hosting-standard-mydataknox.jpg" alt="MyDataKnox - besplatan prijenos hosting računa">
          <div class="divider-after divider-blue"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-grey">
  <div class="row p-3">
    <div class="col-12 col-lg-6 col-xl-4 mb-3">
      <h4 class="font-weight-bold mb-2 text-color-2" i18n>Obavijesti</h4>
      <app-announcements [announcements]="startupResults?.GetAnnouncements" [loading]="loading.startup" [pageSize]="1" [template]="'div'"></app-announcements>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 mb-3" *ngIf="user?.permissions.indexOf('tickets') >= 0">
      <h4 class="font-weight-bold mb-2 text-color-2" i18n>Upiti</h4>
      <div class="panel shadow p-2">
        <div class="d-flex align-items-center justify-content-between">
          <a routerLink="/support" class="flex"><svg-icon src="assets/svg/cpanel-podrska.svg" class="icon icon-20 mr-2"></svg-icon><h5 i18n>Moji upiti</h5></a>
          <a routerLink="/support/ticket/new" class="btn rounded btn-sm btn-green" i18n>Novi upit</a>
        </div>
        <hr>
        <app-ticket-list [departments]="startupResults?.GetSupportDepartments"
                         [ticketCounts]="startupResults?.GetTicketCounts"
                         [tickets]="startupResults?.GetTickets"
                         [outsideLoading]="loading.startup"
                         [template]="'last'"
                         [pageSize]="1">

        </app-ticket-list>
      </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 mb-3" *ngIf="user?.permissions.indexOf('invoices') >= 0">
     <h4 class="font-weight-bold mb-2 text-color-2" i18n>Računi</h4>
     <div class="panel shadow p-2">
       <div class="d-flex align-items-center justify-content-between">
         <a routerLink="/accounting/bills" [queryParams]="{status: 'unpaid'}" class="flex"><svg-icon src="assets/svg/cpanel-racuni.svg" class="icon icon-20 mr-2"></svg-icon><h5 i18n>Zakašnjele uplate</h5></a>
         <a routerLink="/accounting/bills" [queryParams]="{status: 'unpaid'}" class="btn rounded btn-sm btn-green" i18n>Pregledaj</a>
       </div>
       <hr>

       <app-bill-list [status]="'unpaid'"
                      [template]="'unpaid'"
                      [loading]="loading.startup"
                      [invoices]="startupResults?.GetInvoices">

       </app-bill-list>
     </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 mb-3" *ngIf="user?.affiliateid">
      <h4 class="font-weight-bold mb-2 text-color-2" i18n>Suradnički račun</h4>
      <div class="panel shadow p-2">
        <div class="d-flex justify-content-between">
          <a routerLink="/profile/affiliate" class="flex"><svg-icon src="assets/svg/cpanel-podrska.svg" class="icon icon-20 mr-2"></svg-icon><h5 i18n>Suradnički račun</h5></a>
          <a routerLink="/profile/affiliate" class="btn rounded btn-sm btn-green" i18n>Saznaj više</a>
        </div>
        <hr>
        <div *ngIf="affiliate?.balance">
          <span i18n>Trenutno na suradničkom računu imaš {{affiliate.balance}}. Za isplatu sredstava potrebno je skupiti minimalno {{affiliate.affiliatePayoutMinimum}}.</span>
        </div>
        <app-loader *ngIf="loading.affiliate"></app-loader>
      </div>
    </div>
  </div>
</div>
