import {DomainAddon} from './domain-addon';
import {DomainGracePeriod} from './domain-grace-period';
import {DomainAdditionalField} from './domain-additional-field';

export class DomainPricing {
  name: string;
  addons: DomainAddon;
  additionalFields: DomainAdditionalField[];
  categories: [];
  gracePeriod: DomainGracePeriod[];
  group: string;
  eppCode: boolean;
  redemptionPeriod: [];
  register: {};
  renew: {};
  transfer: {};
}
