import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Options} from '@m0t0r/ngx-slider';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {Product} from '../../../_models/product';
import {Currency} from '../../../_models/currency';
import {CartService} from '../../cart.service';
import {ProductService} from '../../../_services/product.service';
import {ProductConfigOption} from '../../../_models/product-config-option';
import {ProductGroup} from '../../../_models/product-group';
import {CustomValidator} from '../../../_components';
import {CartOrderItem} from '../../cart-order-item';
import {SystemService} from '../../../_services/system.service';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-vps',
  templateUrl: './product-vps.component.html'
})
export class ProductVpsComponent implements OnInit, OnDestroy {
  @Input() productGroup: ProductGroup;
  @Input() cartCurrency: Currency;
  @Input() configOptions: ProductConfigOption[];
  // Allowed special characters for windows vps root password are : ! @ # $ % ^ & * _ - + = ?
  // min 6, max 16, min 1 lowe, min 1 upper, min 1 number, some specials
  passPattern = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&*\\+=?])[\\w!@#\\$%\\^&*\\+=?]{8,16}$';
  // min 6, max 16, min 1 lowe, min 1 upper, min 1 number, some specials
  windowsPassPattern = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&*\\+=?])[\\w!@#\\$%\\^&*\\+=?]{8,16}$';
  productId: number;
  product: Product;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  vpsProducts: Product[] = [];
  private subscription: Subscription = new Subscription();
  vpsForm: FormGroup;
  submitted = false;
  tmpProducts: Product[] = [];
  tmpOses: string[] = [];
  tmpOs: string;
  shown = true;
  loading = {
    product: false
  };
  returnToOptionCid: number;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private systemService: SystemService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;
    
    if (!this.productGroup) {
      this.subscription.add(
        this.productService.getProductGroups()
          .pipe(take(1))
          .subscribe(groups => {
            this.productGroup = this.productService.getGroupByTag('vps_standard');
          })
      );
    }
    if (!this.cartCurrency) {
      this.subscription.add(
        this.cartService.getCartCurrency()
          .subscribe(currency => {
            this.cartCurrency = currency;
          })
      );
    }

    this.subscription.add(
      this.route.queryParams
        .pipe(switchMap(params => of(params)))
        .subscribe(params => {
          this.productId = (typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null;
          const cartId = (typeof params.cid !== 'undefined') ? parseInt(params.cid, 10) : null;
          this.returnToOptionCid = (typeof params.returnOption !== 'undefined') ? parseInt(params.returnOption, 10) : null;
          if (this.productId) {
            for (const cfgk in params) {
              if (params.hasOwnProperty(cfgk) && cfgk.indexOf('cfg') > -1) {
                let cfgId = cfgk.replace('cfg[', '');
                cfgId = cfgId.replace(']', '');
                const configOption = new ProductConfigOption();
                configOption.configoptionid = parseInt(cfgId, 10);
                configOption.value = parseInt(params[cfgk], 10);
                this.configOptions.push(configOption);
              }
            }
            const passPattern = this.tmpOs === 'linux' ? this.passPattern : this.windowsPassPattern;
            const newPass = this.systemService.genRandomPattern(passPattern);
            const hostname = 'mdk-vps' + this.systemService.genRandomPattern('^([a-z0-9\\.\\-]){3,4}$');

            this.loading.product = true;
            const prodSub = this.productService.getProductById(this.productId, this.cartCurrency.code)
              .subscribe((product) => {
                this.loading.product = false;
                this.product = product;
                this.vpsForm = this.formBuilder.group({
                  cartId: [null],
                  rootPassword: [newPass, [Validators.required, Validators.minLength(8),
                    Validators.maxLength(16),
                    Validators.pattern(passPattern)]],
                  quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
                  pricingPeriod: [this.product.prices[0].id, Validators.required],
                  basePrice: [null, Validators.required],
                  setupPrice: [null, Validators.required],
                  totalPrice: [null, Validators.required],
                  hostname: [hostname, [Validators.required, Validators.minLength(3),
                    Validators.pattern('^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$')]],
                  config: new FormArray([]),
                  customfields: new FormArray([])
                });

                this.product.configoptions.forEach(option => {
                  let defVal = this.getValueForOption(option, 'first');
                  const ticks = this.getOptionsForCFG(option);
                  const slider = this.getValueForOption(option, 'name', defVal);
                  const defOption = option.options[0];
                  const price = this.getPriceForOption(defOption);
                  if (this.configOptions.length > 0) {
                    const defaultOption = this.configOptions.find(x => x.configoptionid === option.id);
                    defVal = (defaultOption) ? defaultOption.value : defVal;
                  }
                  this.addOptionItem(option.id, defVal, option.name, slider, price, ticks);
                });

                this.product.customfields.forEach(option => {
                  if (option.adminonly === '' && option.showorder === 'on') {
                    const allOptions = option.options.split(',').filter(x => x !== 'none');
                    let options = allOptions;
                    const filters = [];
                    allOptions.forEach(x => {
                      const filterName = x.substr(0, x.indexOf('-'));
                      if (filters.indexOf(filterName) < 0) {
                        filters.push(filterName);
                      }
                    });
                    const defOption = allOptions[0];
                    const defFilterName = defOption.substr(0, defOption.indexOf('-'));
                    if (defFilterName) {
                      options = options.filter(x => x.indexOf(defFilterName) >= 0);
                    }
                    this.addCustomFieldItem(option.id, defOption, option.name, options, allOptions, filters);
                  }
                });

                if (cartId !== null) {
                  this.setDefaultFromCart(cartId);
                }

                this.updateOptionPrices();
              });
            this.subscription.add(prodSub);
          } else {
            this.loading.product = true;
            this.subscription.add(
              this.productService.getProducts(null, null, this.productGroup.id, this.cartCurrency.code)
                .subscribe((products) => {
                  this.loading.product = false;
                  this.vpsProducts = products;
                  products.forEach(x => {
                    const os:string = (x.description && x.description.type.os !== undefined) ? x.description.type.os : null;
                    if (os && this.tmpOses.indexOf(os) < 0) {
                      this.tmpOses.push(os);
                    }
                  })
                  this.product = null;
                })
            );
          }
        })
    );
  }

  generateRootPassword() {
    const passPattern = this.tmpOs === 'linux' ? this.passPattern : this.windowsPassPattern;
    const newPass = this.systemService.genRandomPattern(passPattern);
    this.shown = true;
    this.vpsForm.patchValue({rootPassword: newPass});
  }

  setDefaultFromCart(cartId: number) {
    const cartItem: CartOrderItem = this.cartService.getCartItemByCartId(cartId);
    if (cartItem) {
      this.vpsForm.patchValue({cartId: cartItem.cartId});
      this.vpsForm.patchValue({rootPassword: cartItem.rootPassword});
      this.vpsForm.patchValue({hostname: cartItem.hostname});
      this.vpsForm.patchValue({pricingPeriod: cartItem.pricingPeriodId});
      this.vpsForm.patchValue({quantity: cartItem.quantity});
      cartItem.config.forEach(cfg => {
        const cfgControl = this.c.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cfgControl.get('value').setValue(cfg.value);
      });

      cartItem.customfields.forEach(cfg => {
        const cuControl = this.cu.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cuControl.get('value').setValue(cfg.value);
      });
    }
  }

  selectOses(tmpOs: string) {
    this.tmpOs = tmpOs;
    this.tmpProducts = this.vpsProducts.filter(x => x.description.type.os === tmpOs);
    if (this.tmpProducts.length === 1) {
      const prod = this.tmpProducts[0];
      this.router.navigate(['/cart/product'], {queryParams: {gid: this.productGroup.id, pid: prod.pid}})
    }
  }

  get f() {
    return this.vpsForm.controls;
  }

  get c() {
    return this.f.config as FormArray;
  }

  get cu() {
    return this.f.customfields as FormArray;
  }

  addOptionItem(id: number = null, value: number = null, label: string = null, slider: string = null, price: number = null, ticks: Options)
    : void {
    this.c.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      slider: [slider],
      price: [price],
      ticks
    }));
  }

  addCustomFieldItem(id: number = null, value: number|string = null, label: string = null,
                     options: string[], allOptions: string[], filters: string[]): void {
    this.cu.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      options: [options],
      filters: [filters],
      allOptions: [allOptions]
    }));
  }

  setCustomFieldValue(value: string, customFieldControl) {
    const options = customFieldControl.get('allOptions').value.filter(x => x.indexOf(value) >= 0)
    const defOption = options[0];
    customFieldControl.get('options').setValue(options);
    customFieldControl.get('value').setValue(defOption);
  }

  getIconPath(os) {
    let path = '';
    if (os === null) {
      path = '';
    } else if (os.indexOf('none') >= 0) {
      path = '';
    } else if (os.indexOf('centos') >= 0) {
      path = 'assets/svg/centos.svg';
    } else if (os.indexOf('scientific') >= 0) {
      path = 'assets/svg/scientific_linux.svg';
    } else if (os.indexOf('ubuntu') >= 0) {
      path = 'assets/svg/ubuntu.svg';
    } else if (os.indexOf('fedora') >= 0) {
      path = 'assets/svg/fedora.svg';
    } else if (os.indexOf('debian') >= 0) {
      path = 'assets/svg/debian.svg';
    } else if (os.indexOf('Windows') >= 0) {
      path = 'assets/svg/windows.svg';
    } else if (os.indexOf('almalinux') >= 0) {
      path = 'assets/svg/almalinux.svg';
    }
    return path;
  }

  viewInput() {
    this.shown = !this.shown;
  }

  updateCfgOption(id, cfg) {
    const label = cfg.controls.ticks.value.stepsArray.find(x => x.value === id.value);
    cfg.controls.slider.setValue(label.legend);
    this.updateOptionPrices();
  }

  /**
   * Gets current product pricing period name.
   */
  getPricingPeriodName(): string {
    if (this.f.pricingPeriod.value !== null) {
      return this.product.prices.find(p => p.id === this.f.pricingPeriod.value).nameTranslated;
    }
    return '';
  }

  getPriceForOption(option: any) {
    const prices = option.pricing[this.cartCurrency.code];
    const pricingPeriod = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    const price = parseFloat(prices[pricingPeriod.name]);
    return (price <= 0) ? 0 : price;
  }

  setPricingPeriod(price: {id: number, name: string}) {
    this.product.pricingPeriodId = price.id;
    this.updateOptionPrices();
  }

  getPricing(prices: any, selectedId: number, field: string): number | string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  updateOptionPrices() {
    let totalPrice = 0;

    const basePrice = this.getCurrentPeriodPrice('value');
    this.vpsForm.patchValue({basePrice});
    totalPrice += this.f.quantity.value * basePrice;
    this.vpsForm.patchValue({basePrice});
    const setupPrice = this.getSetupPrice('setupfee');
    this.vpsForm.patchValue({setupPrice});

    this.c.controls.forEach(cntrl => {
      const productConfig = this.product.configoptions.find(x => x.id === cntrl.get('id').value);
      const option = productConfig.options.find(x => x.id === cntrl.value.value);
      const price = this.f.quantity.value * this.getPriceForOption(option);
      totalPrice += price;
      cntrl.get('price').setValue(price);
      cntrl.get('slider').setValue(option.name);
    });

    this.vpsForm.patchValue({totalPrice});
  }

  getOptionsForCFG(cfgOption) {
    const ticks: any[] = [];
    cfgOption.options.forEach(opt => {
      ticks.push({
        value: opt.id,
        legend: opt.name,
      });
    });
    const options: Options = {
      showTicks: true,
      showTicksValues: true,
      showSelectionBar: true,
      stepsArray: ticks
    };

    return options;
  }

  getCurrentPeriodPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    if (field) {
      return currentPrice[field];
    }
    return currentPrice;
  }

  getProductName() {
    return this.product.translated_product_name[this.localeId] !== undefined ?
      this.product.translated_product_name[this.localeId] : this.product.name;
  }

  getValueForOption(configOption, field: string, id: number = null) {
    const optionIds = configOption.options.map(x => x.id);
    let value;
    switch (field) {
      case 'min':
        value = Math.min(...optionIds);
        break;
      case 'minSlider':
        const m = Math.min(...optionIds);
        const minV = configOption.options.find(x => x.id === m);
        value = minV.name;
        break;
      case 'max':
        value = Math.max(...optionIds);
        break;
      case 'maxSlider':
        const v = Math.max(...optionIds);
        const maxV = configOption.options.find(x => x.id === v);
        value = maxV.name;
        break;
      case 'name':
        value = (id) ? configOption.options.find(x => x.id === id).name : configOption.options.map(x => x.name);
        break;
      case 'first':
        value = configOption.options.length > 0 ? configOption.options[0].id : null;
        break;
    }

    return value;
  }

  getSetupPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPeriodPrice = this.getCurrentPeriodPrice();
    const feeSuffix = currentPeriodPrice.name.charAt(0);
    const productCurrencyFee = this.product.pricing[this.cartCurrency.code];
    if (field) {
      const fieldValue = parseFloat(productCurrencyFee[feeSuffix + field]);
      return (fieldValue < 0) ? 0 : fieldValue;
    }
    return productCurrencyFee;
  }

  getProductOs(product: Product) {
    const os = (product.description && product.description.type.os !== undefined) ? product.description.type.os : null;
    return (os) ? os.toLowerCase() : os;
  }

  /**
   * Removes one quantity from current product
   * 1 is the lower limit
   */
  removeQuantity() {
    if (this.vpsForm.value.quantity < 2) {
      return false;
    }
    this.vpsForm.patchValue({quantity: --this.vpsForm.value.quantity});
    this.updateOptionPrices();
  }

  /**
   * Adds quantity to current product
   */
  addQuantity() {
    this.vpsForm.patchValue({quantity: ++this.vpsForm.value.quantity});
    this.updateOptionPrices();
  }

  updateCart(createNew: boolean = false) {
    this.submitted = true;
    if (this.vpsForm.invalid) {
      return false;
    }
    const name = this.getProductConfigName();
    const desc = this.getProductConfigDescription();
    let cartItem = this.cartService.generateCartItem(this.product, this.productGroup, name, desc, this.vpsForm.value);
    cartItem = this.cartService.addOrderItem(cartItem);
    this.vpsForm.patchValue({cartId: cartItem.cartId});
    this.submitted = false;
    return (createNew) ?
      this.router.navigate(['/cart/product/'], {queryParams: {gid: this.productGroup.id}}) :
      this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  getProductConfigName() {
    return this.productService.getName(this.product);
  }

  getProductConfigDescription() {
    let desc = '';
    this.vpsForm.value.config.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.vpsForm.value.config.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].slider}${divider}`;
    });
    desc += ', ';
    this.vpsForm.value.customfields.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.vpsForm.value.customfields.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].value}${divider}`;
    });

    return desc;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
