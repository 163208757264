<ng-container *ngIf="template === 'list'">
  <div class="d-flex justify-content-between bd-bottom align-items-center p-3 bg-white">
    <h4 class="font-weight-bold text-color-2 pl-2" i18n>Računi</h4>
    <div>
      <label for="filter" class="mr-3" i18n>Filter:</label>
      <select id="filter" class="rounded-light width-170 mr-3" (change)="searchBillsByStatus($event.target.value)">
        <option value="all" [selected]="status === 'all'" i18n>Sve</option>
        <option value="paid" [selected]="status === 'paid'" i18n>Plaćeno</option>
        <option value="unpaid" [selected]="status === 'unpaid'" i18n>Neplaćeno</option>
        <option value="cancelled" [selected]="status === 'cancelled'" i18n>Otkazano</option>
      </select>
    </div>
  </div>

  <div class="p-3">
    <div class="container">

      <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="invoices?.length">
        <div class="col-3 d-flex align-items-center p-1" (click)="sortBills('id')">
          <span class="mr-2" i18n>Broj ponude/računa</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-2"
                      *ngIf="showFilterIcon('id','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white"
                      *ngIf="showFilterIcon( 'id','desc')"></svg-icon>
          </div>
        </div>

        <div class="col-2 d-flex align-items-center p-1" (click)="sortBills('date')">
          <span class="mr-2" i18n>Datum izdavanja</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-2"
                      *ngIf="showFilterIcon('date','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white"
                      *ngIf="showFilterIcon('date','desc')"></svg-icon>
          </div>
        </div>

        <div class="col-2 d-flex align-items-center p-1"  (click)="sortBills('duedate')">
          <span class="mr-2" i18n>Rok za plaćanje</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-2"
                      *ngIf="showFilterIcon('duedate','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white"
                      *ngIf="showFilterIcon('duedate','desc')"></svg-icon>
          </div>
        </div>

        <div class="col-3 d-flex align-items-center p-1" (click)="sortBills('total')">
          <span class="mr-2" i18n>Iznos za platiti</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-2"
                      *ngIf="showFilterIcon('total','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white"
                      *ngIf="showFilterIcon('total','desc')"></svg-icon>
          </div>
        </div>

        <div class="col-2 d-flex align-items-center p-1"  (click)="sortBills('status')">
          <span class="mr-2" i18n>Status</span>
          <div class="d-flex flex-column">
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white icon-reverse mb-2"
                      *ngIf="showFilterIcon('status','asc')"></svg-icon>
            <svg-icon src="assets/svg/cpanel-strelica.svg" class="icon icon-7 color-white"
                      *ngIf="showFilterIcon('status','desc')"></svg-icon>
          </div>
        </div>
      </div>

     <ng-container *ngIf="invoices?.length">
       <div class="row bg-white bd-bottom p-2" [class.mb-3]="last" *ngFor="let invoice of invoices; let last = last;">
         <div class="col-5 mb-2 font-weight-bold d-lg-none" i18n>Broj ponude/računa</div>
         <div class="col-7 mb-2 col-lg-3 mb-lg-0">
           <a routerLink="../bill/{{invoice.id}}">{{invoice.status === 'Paid' && invoice.invoicenum !== '' ? invoice.invoicenum : invoice.id}}</a>
         </div>
         <div class="col-5 mb-2 font-weight-bold d-lg-none" i18n>Datum izdavanja</div>
         <div class="col-7 mb-2 col-lg-2 mb-lg-0">{{invoice.date | date:'shortDate'}}</div>

         <div class="col-5 mb-2 font-weight-bold d-lg-none" i18n>Rok za plaćanje</div>
         <div class="col-7 mb-2 col-lg-2 mb-lg-0">{{invoice.duedate | date:'shortDate'}}</div>

         <div class="col-5 mb-2 font-weight-bold d-lg-none" i18n>Iznos za platiti</div>
         <div class="col-7 mb-2 col-lg-3 mb-lg-0">
          <span class="mr-2" *ngIf="currentCurrency">{{invoice.total | currency : invoice.currencycode : 'symbol-narrow'}}</span>
         </div>

         <div class="col-5 mb-2 font-weight-bold d-lg-none" i18n>Status</div>
         <div class="col-7 mb-2 col-lg-2 mb-lg-0">
           <a routerLink="../bill/{{invoice.id}}" [ngClass]="getInvoiceStatus(invoice)">
             <span *ngIf="invoice.status === 'Paid'" i18n>Plaćeno</span>
             <span *ngIf="invoice.status === 'Unpaid'" i18n>Neplaćeno</span>
             <span *ngIf="invoice.status === 'Cancelled'" i18n>Otkazano</span>
           </a>
         </div>
       </div>
     </ng-container>

      <app-pager [pager]="pager"></app-pager>
      <div *ngIf="invoices?.length === 0" i18n>Nemaš računa</div>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="template === 'unpaid'">
  <div *ngIf="invoices?.length > 0">
    <span i18n>Imaš</span>&nbsp;{{invoices.length}}&nbsp;<span
    i18n>neplaćenih računa u iznosu od</span>&nbsp;{{ getInvoiceSum() | currency : currentCurrency?.code : 'symbol-narrow' }}
    <span i18n>Izvrši uplatu kako bi se izbjegli prekidi u radu usluga</span>
  </div>
  <div *ngIf="invoices?.length === 0" i18n>Nemaš neplaćenih računa</div>
  <app-loader *ngIf="loading"></app-loader>
</ng-container>

