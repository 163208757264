<ng-container *ngIf="!user.subaccount">
  <form [formGroup]="parentForm" (ngSubmit)="onSubmit()">
    <app-profile-details [submitted]="submitted" [user]="user" [edit]="true" [title]="title" [parentForm]="parentForm" class="mb-3"></app-profile-details>

    <div class="d-flex justify-content-between p-3">
      <a routerLink="../details" class="btn btn-primary rounded-light" i18n>Odustani</a>
      <button class="btn btn-green rounded-light" i18n>Spremi</button>
    </div>
  </form>
</ng-container>
