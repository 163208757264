import {Component, OnDestroy, OnInit, LOCALE_ID, Inject} from '@angular/core';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {StartupResults} from '../_models/startup-results';
import {SystemService} from '../_services/system.service';
import {AuthenticationService, ClientService} from '../_services';
import {Affiliate} from '../_models/affiliate';
import {Client} from '../_models';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  startupResults: StartupResults;
  loading = {
    startup: false,
    affiliate: false
  };
  user: Client;
  affiliate: Affiliate;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private systemService: SystemService,
    private service: ClientService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.loadStartups();
  }

  private loadStartups() {
    if (this.startupResults !== undefined) {
      return false;
    }
    this.loading.startup = true;
    this.loading.affiliate = true;
    const loadStartSub = this.systemService.getStartupRequests({})
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.startup = false;
        this.loading.affiliate = false;
        this.startupResults = res;
        if (this.startupResults.GetAffiliateDetails !== undefined) {
          this.affiliate = this.startupResults.GetAffiliateDetails;
        }
      });
    this.subscription.add(loadStartSub);
  }

  getLocale() {
    return this.localeId;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
