<div class="ticket pl-2 pr-2" [ngClass]="{'bg-color-product' : reply.admin !== '', 'last': last, 'first': first}">
  <div class="p-3" [ngClass]="{'bd-bottom' : !last}">
    <div class="row d-flex justify-content-between flex-wrap" [ngClass]="{'mb-3' : !print}">
      <div class="col-12 col-md-6 col-xl-8 mb-3 mb-md-0 user">
        <svg-icon src="assets/svg/cpanel-podrska.svg" class="icon icon-20 mr-2 icon-language" *ngIf="reply.admin !== '' && !print"></svg-icon>
        <svg-icon src="assets/svg/email-korisnik.svg" class="icon icon-20 mr-2 icon-language" *ngIf="reply.admin === '' && !print"></svg-icon>
        <span class="color-blue font-weight-bold" *ngIf="reply.name" >{{reply.name}}</span>
        <span class="color-dark-blue font-weight-bold" *ngIf="reply.admin !== ''" i18n>Korisnička podrška ({{reply.admin}})</span>
      </div>
      <div class="col-12 col-md-6 col-xl-4 d-flex justify-content-md-end">
        <svg-icon src="assets/svg/attachment.svg" class="icon icon-15 mr-3" *ngIf="displayAttachmentIcon()"></svg-icon>
        <div class="date">{{reply.date | date:'fullDate' }} {{reply.date | date:'shortTime' }}</div>
      </div>
    </div>

    <div class="message" [ngClass]="{'mb-3' : !print}">
      <div [innerHTML]="reply.message" *ngIf="reply.message"></div>
    </div>
    <div class="attachments" *ngIf="reply.attachment.length > 0 && !print">
      <div class="d-flex">
        <span class="font-weight-bold mb-2 mr-2" i18n>Prilozi</span>({{reply.attachment.length}})<app-loader *ngIf="downloadingAttachment"></app-loader>
      </div>

      <ul class="p-0">
        <li class="mb-2" *ngFor="let rplAtt of reply.attachment">
          <a href="{{rplAtt.link}}" target="_blank" (click)="downloadAttachment($event, rplAtt)">{{rplAtt.name}}</a>
        </li>
      </ul>
    </div>
    <a class="download-zip-link" #downloadZipLink *ngIf="!print"></a>
  </div>
  <div class="relative" *ngIf="!print && first && !hide && !print">
    <hr class="bg-color2 mb-2">
    <span class="absolute border badge badge-pill badge-light" (click)="show()">{{hiddenNumber}}</span>
    <hr class="bg-color2 m-0 mb-3">
  </div>
</div>
