import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {ProductGroup} from '../../../_models/product-group';
import {CartService} from '../../cart.service';
import {AlertService} from '../../../_services';
import {ProductService} from '../../../_services/product.service';

@Component({
  selector: 'app-cart-step-options-domains',
  templateUrl: './step-options-domains.component.html'
})
export class StepOptionsDomainsComponent implements OnDestroy {
  private subscription: Subscription = new Subscription();
  productGroups: ProductGroup[];
  hasAdditional = false;
  loading = {
    groups: false
  };
  domainGroupTags: string[] = ['ssl'];

  constructor(
    private cartService: CartService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected alert: AlertService,
    private productService: ProductService
  ) {
    this.loading.groups = true;
    const groupSub = this.productService.getProductGroups()
      .pipe(take(1))
      .subscribe(groups => {
        this.loading.groups = false;
        groups = groups.filter(x => this.domainGroupTags.indexOf(x.tag) >= 0);
        const groupSetGroups = this.productService.getProductGroupsBySet('whost');
        if (groupSetGroups.length > 0) {
          groups = [...groups, ...groupSetGroups];
        }
        this.productGroups = groups;
      });
    this.subscription.add(groupSub);
  }

  getGroupName(tag: string) {
    return this.productService.getGroupNameByTag(tag);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
