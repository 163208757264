import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cart-progress',
  templateUrl: './cart-progress.component.html'
})
export class CartProgressComponent {
  @Input() step: number;
  @Input() productId: number;
  @Input() groupId: number;
  @Input() cartId: number;
}
