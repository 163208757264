import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { concat, Observable, Subject, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  switchMap,
  tap,
} from "rxjs/operators";

import { CountryISO } from "ngx-intl-tel-input";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import { Contact } from "../contact";
import { AuthenticationService } from "../../../_services";
import { GeoStruct } from "src/app/_models/geo-struct";
import { GeoStructService } from "src/app/_services/geo-struct.service";

@Component({
  selector: 'app-contact-details',
  templateUrl: 'contact-details.component.html'
})
export class ContactDetailsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() user: Contact;
  @Input() edit = false;
  @Input() source: string;
  @Input() isContactSameAsParent: boolean;
  @Output() newPass = new EventEmitter<boolean>();
  @Output() passRequired = new EventEmitter<boolean>();
  shown = false;
  shown1 = false;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  // TooltipLabel = TooltipLabel;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  currentCountry = null;
  currentCountryPhone = null;
  countries = [];

  public minSearchTermLength = 2;
  public posts: GeoStruct[] = [];
  public states: GeoStruct[] = [];
  public cities$: Observable<GeoStruct[]>;
  public citiesInput$ = new Subject<string>();
  public loadingCities = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private geoStructService: GeoStructService,
  ) {}

  generateRootPassword() {
    this.shown = true;
    this.newPass.emit(true);
  }

  ngOnInit(): void {
    this.parentForm = (this.parentForm === undefined) ? this.formBuilder.group({
      phonenumber: [null],
      telephoneNumber: [null, Validators.required]
    }) : this.parentForm;

    const defaultLanguage = 'croatian';
    let currentCountry = 'HR';
    let currentCountryPhone = 'HR';

    if (this.user) {
      this.user.persontype = (this.user.companyname !== '') ? 'business' : 'private';
      const phoneNumber = (this.user.phonenumber !== null && this.user.phonenumber !== '') ? this.user.phonenumber.split('.') : '';
      const phone = (phoneNumber.length > 0) ? phoneNumber[1] : '';
      const telPhone = (phoneNumber.length > 0) ? phoneNumber[0] + phoneNumber[1] : '';
      this.parentForm.patchValue({phonenumber: phone});
      this.parentForm.patchValue({telephoneNumber: telPhone});
      currentCountry = (this.user.country !== undefined) ? this.user.country.toUpperCase() : currentCountry;
      currentCountryPhone = currentCountry;
    }
    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }
    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);

    this.parentForm.patchValue({country : currentCountry});
    this.parentForm.patchValue({language : defaultLanguage});
    
    this.loadCities();
  }

  viewInput() {
    this.shown = !this.shown;
  }

  hasPermission(permission: string) {
    return (!this.authService.currentUserValue.subaccount || (this.authService.currentUserValue.permissions.indexOf(permission) >= 0));
  }

  get f() {
    return this.parentForm.controls;
  }

  toggleSubaccount() {
    this.passRequired.emit(this.f.subaccount.value);
  }

  telInvalidPhone() {
    const defaultClass = (this.edit) ? 'form-control' : 'form-control-plaintext';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }

  public trackById(item: any) {
    return item.id;
  }

  public loadCities(): void {
    this.cities$ = concat(
      of([]), // Default to empty array
      this.citiesInput$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.minSearchTermLength;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => (this.loadingCities = true)),
        switchMap((term) => {
          return this.geoStructService.getPlaces(term).pipe(
            catchError(() => of([])), // Set empty array on error
            tap(() => this.loadingCities = false)
          );
        })
      )
    );
  }

  public onCountryChange(event?: { code: string; name: string }): void {
    // Whenever the country changes, clear out the post code, city and state
    this.parentForm.patchValue({
      postcode: null,
      city: null,
      state: null,
    });
  }

  public onCityChange(city: GeoStruct | null): void {
    // On city change, try to find the state and fetch posts for the city
    if (city) {
      this.geoStructService
        .getStatesForAPlace(city.id)
        .subscribe((counties) => {
          this.states = counties;

          // If we have only one state, we can set it as the default value
          if (counties.length === 1) {
            const state = counties[0];
            this.parentForm.patchValue({ state: state.name });
          }

          // If we have multiple counties, we can show them in the dropdown
          if (counties.length > 1) {
            this.parentForm.patchValue({ state: null });
          }
        });

      this.geoStructService.getPostsForAPlace(city.id).subscribe((posts) => {
        this.posts = posts;

        // If we have only one post, we can set it as the default value
        if (posts.length === 1) {
          const post = posts[0];
          this.parentForm.patchValue({ postcode: post.code });
        }

        // If we have multiple posts, we can show them in the dropdown
        if (posts.length > 1) {
          this.parentForm.patchValue({ postcode: null });
        }
      });
    }
  }
}
