import {Component, ElementRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {ProductGroup} from '../../../_models/product-group';
import {Currency} from '../../../_models/currency';
import {Product} from '../../../_models/product';
import {ProductService} from '../../../_services/product.service';
import {CartService} from '../../cart.service';
import {CustomValidator} from '../../../_components';
import {CartOrderItem} from '../../cart-order-item';
import {ProductDomainItem} from '../../product-domain-item';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-ssl',
  templateUrl: './product-ssl.component.html'
})
export class ProductSslComponent implements OnInit, OnDestroy {
  @Input() productGroup: ProductGroup;
  @Input() cartCurrency: Currency;
  @ViewChild('changeDomainProduct') confirmDomainModal: ElementRef;
  private subscription: Subscription = new Subscription();
  domains: ProductDomainItem[] = [];
  submitted = false;
  loading = {
    product: false
  };
  product: Product;
  sslForm: FormGroup;
  sslProducts: Product[];
  productId: number;
  modalRef: BsModalRef;
  connectedCartItem: CartOrderItem;
  domainGroup: AbstractControl;
  returnToOptionCid: number;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.resetForm();

    const domainsSub = this.cartService.domainItems$
      .subscribe(domains => {
        if (domains !== null) {
          this.domains = domains;
        }
      });
    this.subscription.add(domainsSub);
  }

  resetForm() {
    this.sslForm = this.formBuilder.group({
      cartId: [null],
      quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
      pricingPeriod: [null, Validators.required],
      totalPrice: [null, Validators.required],
      regPeriod: [null],
      customfields: new FormArray([])
    });
  }

  get f() {
    return this.sslForm.controls;
  }

  get cu() {
    return this.f.customfields as FormArray;
  }

  get d() {
    return this.domainGroup as FormArray;
  }

  ngOnInit(): void {
    this.displayHrk = this.config.displayHrk;

    if (!this.productGroup) {
      this.subscription.add(
        this.productService.getProductGroups()
          .pipe(take(1))
          .subscribe(groups => {
            this.productGroup = this.productService.getGroupByTag('backup_r1');
          })
      );
    }
    if (!this.cartCurrency) {
      this.subscription.add(
        this.cartService.getCartCurrency()
          .subscribe(currency => {
            this.cartCurrency = currency;
          })
      );
    }
    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        this.productId = (typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null;
        const cartId = (typeof params.cid !== 'undefined') ? parseInt(params.cid, 10) : null;
        this.returnToOptionCid = (typeof params.returnOption !== 'undefined') ? parseInt(params.returnOption, 10) : null;
        this.loading.product = true;
        if (this.productId) {
          const prodSub = this.productService.getProductById(this.productId, this.cartCurrency.code)
            .pipe(take(1))
            .subscribe((product) => {
              this.loading.product = false;
              this.product = product;
              this.product.customfields.forEach(option => {
                if (option.adminonly === '' && option.showorder === 'on') {
                  const ppId = (option.name.indexOf('Domain Name') >= 0) ? product.pricingPeriodId : null;
                  this.addCustomFieldItem(option.id, null, option.name, option.options.split(','), ppId);
                  if (option.name.indexOf('Domain Name') >= 0) {
                    this.domainGroup = this.cu.controls.find(x => x.value.id === option.id);
                  }
                }
              });

              if (this.domains !== null) {
                const firstFreeDomain = this.domains.find(x => x.cartProductId === undefined);
                if (firstFreeDomain) {
                  this.domainGroup.get('value').setValue(firstFreeDomain.name);
                  this.domainGroup.get('pricingPeriodId').setValue(firstFreeDomain.pricingPeriodId);
                }
              }

              if (cartId !== null) {
                this.setDefaultFromCart(cartId);
              } else {
                this.sslForm.patchValue({pricingPeriod: this.product.prices[0].id});
              }

              this.updateOptionPrices();
            });
          this.subscription.add(prodSub);
        } else {
          this.product = null;
          this.resetForm();
          const subA = this.productService.getProducts(null, null, this.productGroup.id, this.cartCurrency.code, true)
            .subscribe((products) => {
              this.loading.product = false;
              this.sslProducts = products;
              this.setPricingDefaults();
            });
          this.subscription.add(subA);
        }
      });
    this.subscription.add(routerQidSub);
  }

  addCustomFieldItem(id: number = null, value: number = null,
                     label: string = null, options: string[], pricingPeriodId: number = null): void {
    const vds = (label.indexOf('Domain Name') >= 0) ?
      [Validators.minLength(3),
        Validators.pattern('^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$'),
        Validators.required] : Validators.required;
    const group = this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, vds],
      label: [label],
      options: [options],
      pricingPeriodId
    });
    this.cu.push(group);
  }

  showCustomField(customFieldId: number) {
    return (customFieldId !== this.domainGroup.get('id').value);
  }

  checkDomain(template: any) {
    const domain = this.domains.find(x => x.name === this.domainGroup.get('value').value);
    if (domain !== undefined && typeof domain.cartProductId !== 'undefined') {
      this.connectedCartItem = this.cartService.getCartItemByCartId(domain.cartProductId);
      // this.modalRef = this.modalService.show(template);
    } else {
      this.connectedCartItem = null;
    }
  }

  addTagFn(name: string) {
    const customDomain = new ProductDomainItem();
    customDomain.name = name;
    customDomain.pricingPeriodId = null;
    customDomain.tag = true;
    return customDomain;
  }

  getSelectedDomain(field: string = null) {
    const selectedDomain = this.domains.find(x => x.name === this.domainGroup.get('value').value);
    const fieldData = (selectedDomain && selectedDomain[field] !== undefined) ? selectedDomain[field] : null;
    return (field) ? fieldData : selectedDomain;
  }

  setDefaultFromCart(cartId: number) {
    const cartItem: CartOrderItem = this.cartService.getCartItemByCartId(cartId);
    if (cartItem) {
      this.sslForm.patchValue({cartId: cartItem.cartId});
      this.sslForm.patchValue({rootPassword: cartItem.rootPassword});
      this.sslForm.patchValue({pricingPeriod: cartItem.pricingPeriodId});
      this.sslForm.patchValue({quantity: cartItem.quantity});
      cartItem.customfields.forEach(cfg => {
        const cuControl = this.cu.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cuControl.get('value').setValue(cfg.value);
      });
    }
  }

  updateOptionPrices() {
    let totalPrice = 0;

    const basePrice = this.getCurrentPeriodPrice('value');
    this.sslForm.patchValue({basePrice});
    totalPrice += this.f.quantity.value * basePrice;
    this.sslForm.patchValue({basePrice});
    const setupPrice = this.getSetupPrice('setupfee');
    this.sslForm.patchValue({setupPrice});
    this.sslForm.patchValue({totalPrice});
  }

  getCurrentPeriodPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    if (field) {
      return currentPrice !== undefined ? currentPrice[field] : 0;
    }
    return currentPrice !== undefined ? currentPrice : 0;
  }

  getSetupPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPeriodPrice = this.getCurrentPeriodPrice();
    const feeSuffix = currentPeriodPrice.name !== undefined ? currentPeriodPrice.name.charAt(0) : '';
    const productCurrencyFee = this.product.pricing[this.cartCurrency.code];
    if (field) {
      const fieldValue = parseFloat(productCurrencyFee[feeSuffix + field]);
      return (fieldValue < 0) ? 0 : fieldValue;
    }
    return productCurrencyFee;
  }

  setPricingDefaults(): void {
    this.productService.setPricingDefaults(this.sslProducts);
  }

  getDescription(sslProduct: Product): string {
    const description = this.productService.getDescription(sslProduct);
    if (description === '') {
      return this.productService.getDescription(sslProduct, true);
    }
    return description;
  }

  getName(sslProduct: Product): string {
    return this.productService.getName(sslProduct);
  }

  getGroupName(): string {
    return this.productService.getGroupNameByTag(this.productGroup.tag);
  }

  getPricing(prices: any, selectedId: number, field: string): number | string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  setCustomFieldValue(value: string, customFieldControl) {
    customFieldControl.get('value').setValue(value);
  }

  setProduct(sslProduct: Product): void | boolean {
    this.router.navigate(['/cart/product'], {queryParams: {gid: sslProduct.gid, pid: sslProduct.pid}});
  }

  updateCart() {
    this.submitted = true;
    if (this.sslForm.invalid) {
      if (this.d.get('value').errors.pattern !== undefined) {
        this.domains = [...this.domains];
      }
      return false;
    }

    const name = this.getName(this.product);
    const desc = this.getDescription(this.product);
    let cartItem = this.cartService.generateCartItem(this.product, this.productGroup, name, desc, this.sslForm.value);
    cartItem.domain = this.domainGroup.get('value').value;
    delete cartItem.rootPassword;
    cartItem = this.cartService.addOrderItem(cartItem);
    if (cartItem.cartId !== undefined) {
      let domain = this.getSelectedDomain();
      if (domain) {
        domain = this.cartService.domainInCart(domain);
        domain.cartProductId = cartItem.cartId;
      }
      if (domain !== undefined) {
        this.cartService.addDomain(domain);
      }
    }
    this.sslForm.patchValue({cartId: cartItem.cartId});
    this.cu.clear();
    this.domainGroup = null;
    this.submitted = false;
    return this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProductTranslatedName(prod: Product) {
    if (prod.translated_product_name[this.localeId] !== undefined) {
      return prod.translated_product_name[this.localeId];
    }
    return prod.name;
  }
}
