import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';

import {Product} from '../../_models/product';
import {ProductService} from '../../_services/product.service';
import {AlertService} from '../../_services';

@Component({
  selector: 'app-product-cancel',
  templateUrl: './product-cancel.component.html'
})
export class ProductCancelComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Input() modalRef: BsModalRef;
  @Output() changed = new EventEmitter<boolean>();
  private subscription: Subscription = new Subscription();
  cancelForm: FormGroup;
  loading = {
    product: false,
    form: false
  };
  submitted = false;

  constructor(
    private service: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    this.cancelForm = this.formBuilder.group({
      reason: [null, [Validators.required, Validators.minLength(30)]],
      type: [null, Validators.required],
      productId: [null, Validators.required]
    });
  }

  ngOnInit() {
    if (this.product === undefined) {
      return false;
    }
    this.cancelForm.patchValue({productId: this.product.id});
    this.cancelForm.patchValue({type: 'eibp'});
  }

  get f() {
    return this.cancelForm.controls;
  }

  cancelService() {
    this.submitted = true;
    if (!this.cancelForm.valid) {
      return false;
    }
    this.loading.form = true;
    const cancelProductSub = this.service.cancelProductService(
      this.cancelForm.value.productId, this.cancelForm.value.type, this.cancelForm.value.reason)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.form = false;
        if (res) {
          this.submitted = false;
          this.alertService.success($localize`Usluga je uspješno otkazana`);
          this.modalRef.hide();
          this.changed.emit(true);
        }
      });
    this.subscription.add(cancelProductSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
