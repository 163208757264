<div class="d-flex justify-content-between bd-bottom p-3 bg-white align-items-center" *ngIf="user">
  <div class="d-flex">
    <a routerLink="{{getBackLink()}}" tooltip="Na popis kontakata" i18n-tooltip="Na popis kontakata" class="link mr-3" i18n>&#8592;</a>
    <h2 i18n>Uređivanje kontakta: {{ user.firstname }} {{ user.lastname }}</h2>
  </div>
  <button (click)="openModal(deleteContactModal)" i18n class="btn btn-danger rounded-light">Obriši kontakt</button>
</div>

<div class="p-3">
 <ng-container *ngIf="user && !loading">
   <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
     <div class="mb-4">
      <app-contact-details [isContactSameAsParent]="isContactSameAsParent" [submitted]="submitted" [edit]="true" (newPass)="generateRootPassword()" (passRequired)="togglePassRequired($event)" [user]="user" [parentForm]="updateForm"></app-contact-details>
     </div>
     <hr>

     <div class="d-flex justify-content-between">
       <a routerLink="../" class="btn btn-primary rounded-light" i18n>Nazad</a>
       <button class="btn btn-green rounded-light" i18n>Spremi</button>
     </div>
   </form>
 </ng-container>
  <app-loader *ngIf="loading"></app-loader>
</div>

<ng-template #deleteContactModal>
  <div class="modal-header">
    <h3 i18n>Brisanje kontakta</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p i18n>Jeste li sigurni da želite obrisati ovaj podračun?</p>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn rounded-light" (click)="modalRef.hide()" i18n>Odustani</button>
    <button type="button" class="btn rounded-light btn-danger" (click)="delete(user)" i18n>Da</button>
  </div>
</ng-template>

<ng-template #cancelDisableSubaccount>
  <div class="modal-header">
    <h3 i18n>Deaktivacija podračuna</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p i18n>Jeste li sigurni da želite deaktivirati podračun?</p>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn rounded-light" (click)="modalRef.hide()" i18n>Odustani</button>
    <button type="button" class="btn rounded-light btn-danger" (click)="onSubmit(true)" i18n>Da</button>
  </div>
</ng-template>
