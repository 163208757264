<!--<div *ngIf="!isModal" class="d-flex bd-bottom p-3 bg-white">-->
<!--  <a routerLink="/profile/cards" tooltip="Na listu kartica" i18n-tooltip="Na listu kartica" class="link-black mr-3">&#8592;</a>-->
<!--  <h3 class="color-dark-blue" i18n>Dodavanje nove kreditne / debitne kartice</h3>-->
<!--</div>-->

<div class="container-fluid">
  <div class="row p-3">
    <form class="card shadow rounded-light p-3 credit-card bg-grey col-12" [ngClass]="{
      'col-md-10 col-xl-6 offset-md-1 offset-xl-3': isModal
    }" id="payment-form" [formGroup]="paymentNewForm" (ngSubmit)="submitForm()">
      <ng-container [ngSwitch]="paymentGateway">
        <ng-container *ngSwitchCase="'monri'">
          <app-loader *ngIf="loading.gateway"></app-loader>
          <div id="card-element" class="mb-3"  #cardElement>
            <!-- A Monri Component will be inserted here. -->
          </div>

          <div class="form-group row mb-3" *ngIf="!maestro">
            <div class="col">
              <label for="description" i18n>Opis kartice</label>
              <input id="description" type="text" formControlName="description" class="form-control"
                     [ngClass]="{ 'is-invalid': submitted && f.description.errors }"/>
              <div *ngIf="submitted && f.description.errors" class="invalid-feedback w-100">
                <div *ngIf="f.description.errors.required" i18n>Opis je obavezno polje</div>
                <div *ngIf="f.description.errors.minlength || f.description.errors.maxlength" i18n>Opis mora biti duljine 3 do 50 znakova</div>
              </div>
            </div>
          </div>

          <!-- Used to display Component errors. -->
          <div id="card-errors" role="alert"></div>

          <div class="text-right">
            <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="loading.order"></fa-icon>
            <button type="submit" class="col-3 btn btn-green" *ngIf="!loading.order">
              <span *ngIf="isModal" i18n>Plati</span>
              <span *ngIf="!isModal" i18n>Spremi</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>

<ng-template #paymentFailedWarningModal>
  <div class="modal-header">
    <div class="d-flex align-items-center">
      <h2 i18n>Tvoja transakcija je odbijena. Molimo ponovite proces plaćanja.</h2>
    </div>
  </div>
</ng-template>
