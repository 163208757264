import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {Email} from '../email';
import {EmailService} from '../email.service';
import {Pager} from '../../tickets/pager';

@Component({
    templateUrl: 'email-list.component.html'
})
export class EmailListComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    loading = false;
    emails: Email[];
    pager: Pager;
    pageSize = 20;
    modalRef: BsModalRef;
    selectedItem: Email;

    constructor(
        private service: EmailService,
        private route: ActivatedRoute,
        private modalService: BsModalService
    ) {}

  ngOnInit() {
    const emailsCountsSubs = this.route.queryParams.pipe(
      switchMap(params => {
        this.loading = true;
        const newParams = {
          page: (typeof params.page !== 'undefined') ? params.page : 1
        };
        return this.service.getList(newParams, this.pageSize);
      })
    )
      .subscribe((res) => {
        this.loading = false;
        this.pager = res.pager;
        this.emails = res.emails;
      });
    this.subscription.add(emailsCountsSubs);
  }

  readEmail(template: TemplateRef<any>, viewItem: Email) {
    this.selectedItem = viewItem;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
