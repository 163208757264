<div *ngIf="productGroup && !loading.product && cartCurrency">
  <ng-container [ngSwitch]="productSet">
    <app-product-domain *ngSwitchCase="'domain'"></app-product-domain>
    <div *ngSwitchCase="'whost'">
      <ng-container *ngIf="groupSet === null; else whostAccordion">
        <app-product-web-hosting [productGroup]="productGroup" [cartCurrency]="cartCurrency"></app-product-web-hosting>
      </ng-container>
      <ng-template #whostAccordion>
        <accordion [isAnimated]="true" [closeOthers]="true">
          <accordion-group heading="{{whostProduct.name}}" [isOpen]="first" panelClass="product-list" *ngFor="let whostProduct of productGroups; let first = first;">
            <app-product-web-hosting [productGroup]="whostProduct" [hideTitle]="true"></app-product-web-hosting>
          </accordion-group>
        </accordion>
      </ng-template>
    </div>
    <app-product-vps *ngSwitchCase="'vps'" [configOptions]="configOptions" [productGroup]="productGroup" [cartCurrency]="cartCurrency"></app-product-vps>
    <app-product-dedicated-server *ngSwitchCase="'dserver'" [configOptions]="configOptions" [productGroup]="productGroup" [cartCurrency]="cartCurrency"></app-product-dedicated-server>
    <app-product-backup *ngSwitchCase="'backup'" [productGroups]="productGroups" [cartCurrency]="cartCurrency"></app-product-backup>
    <app-product-ssl *ngSwitchCase="'ssl'" [productGroup]="productGroup" [cartCurrency]="cartCurrency"></app-product-ssl>
    <app-product-licence-group *ngSwitchCase="'licence'" [configOptions]="configOptions" [productGroup]="productGroup" [cartCurrency]="cartCurrency"></app-product-licence-group>
  </ng-container>
</div>
