import {Component, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {PaymentMethod} from '../../_models/payment-method';
import {SystemService} from '../../_services/system.service';
import {AlertService, AuthenticationService} from '../../_services';
import {Client} from '../../_models';
import {Currency} from '../../_models/currency';
import {CreditOptions} from '../../_models/credit-options';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InvoiceItem} from '../../accounting/bills/invoice-item';
import {InvoiceService} from '../../accounting/bills/invoice.service';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  templateUrl: 'profile-credit.component.html'
})
export class ProfileCreditComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  modalRef: BsModalRef;
  paymentMethods: PaymentMethod[];
  client: Client;
  currentCurrency: Currency;
  creditOptions: CreditOptions;
  submitted = false;
  creditForm: FormGroup;
  loading = {
    credit: false,
    paymentMethods: false,
    creditOptions: false,
    form: false
  };
  public displayHrk = false;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private systemService: SystemService,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private invoiceService: InvoiceService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.client = this.authenticationService.currentUserValue;
    this.currentCurrency = this.authenticationService.currentCurrencyValue;
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;
    this.loadPamyentMethods();
    this.loadCreditOptions();
  }

  private createForm() {
    this.creditForm = this.formBuilder.group({
      status: ['Unpaid'],
      paymentmethod: [null, [Validators.required]],
      amount: [null, [Validators.required,
        Validators.min(parseInt(this.creditOptions.minimumdeposit.replace('.', ''), 10)),
        Validators.max(parseInt(this.creditOptions.maximumdeposit.replace('.', ''), 10))]],
      items: [[]],
      isAddFunds: [true, [Validators.required]],
      taxrate: [this.client.tax1_value],
      taxrate2: [this.client.tax2_value]
    });
  }

  get f() {
    return this.creditForm.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadCreditOptions() {
    this.loading.creditOptions = true;
    this.loading.credit = true;
    const creditOptionLoad = this.systemService.getCreditOptions()
      .subscribe((res) => {
          this.loading.creditOptions = false;
          this.loading.credit = false;
          this.creditOptions = res;
          this.client.credit = res.creditbalanceNum;
          this.authenticationService.updateUser(this.client);
          this.createForm();
        }
      );
    this.subscription.add(creditOptionLoad);
  }

  private loadPamyentMethods() {
    this.loading.paymentMethods = true;
    const payMethSub = this.systemService.getPaymentMethods()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.loading.paymentMethods = false;
          this.paymentMethods = res;
        }
      );
    this.subscription.add(payMethSub);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  submitCredit() {
    this.submitted = true;
    if (!this.creditForm.valid) {
      return false;
    }
    this.loading.form = true;
    const creditItem = new InvoiceItem();
    creditItem.description = 'Credit';
    creditItem.taxed = !this.client.taxexempt;
    creditItem.amount = this.creditForm.value.amount;
    this.creditForm.patchValue({items: [creditItem]});
    const uploadCreditSub = this.invoiceService.createInvoice(this.creditForm.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.form = false;
        if (res !== null) {
          if (res.result === 'success') {
            this.alertService.success($localize`Uplata kredita je uspješno izvršena`, true);
            this.router.navigate([`./accounting/bill/${res.invoiceid}`]);
          } else {
            this.alertService.error(res.status);
          }
        } else {
          this.alertService.error($localize`Uplata kredita nije uspjela!`);
        }
      });
    this.subscription.add(uploadCreditSub);
  }
}
