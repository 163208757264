<div *ngIf="hosts?.length">
  <ng-container *ngIf="product; else chooseProduct;">
    <form [formGroup]="hostingForm" (ngSubmit)="updateCart()">
      <div class="d-flex p-3 bg-color1 align-items-center">
        <button type="button" class="btn btn-outline-light rounded-light mr-3 color-white"
                tooltip="Na listu web hosting proizvoda" i18n-tooltip="Na listu web hosting proizvoda"
                (click)="resetProduct()" *ngIf="!connectedCartItem"><</button>
        <h3 class="color-white" *ngIf="connectedCartItem" i18n>Web hosting - uređivanje</h3>
        <h3 class="color-white" *ngIf="!connectedCartItem" i18n>Web hosting - odabir domene</h3>
      </div>

      <div class="p-3 mb-4">
        <div class="mb-3">
          <h5 class="font-weight-bold mb-2">{{getName(product)}}</h5>

          <div class="d-flex align-items-center mb-2">
            <div class="font-weight-bold mr-2" i18n>Ciklus plaćanja:</div>
            <div>
              <!-- If there's only one type of prices, show a simple pricing -->
              <p *ngIf="product.prices.length === 1">
                <ng-container *ngIf="product.prices.length === 1">
                  <!-- Check if there are discounts available -->
                  <p *ngIf="product.discountprices?.length === 1">
                    <span class="font-weight-bold">
                      {{product.discountprices?.[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
                    </span>
                    <span class="text-danger line-through font-italic">
                      {{product.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
                    </span>
                    <span>&nbsp;/&nbsp;{{product.discountprices?.[0].nameTranslated}}</span>
                  </p>

                  <!-- If there are not discounts available, show regular price -->
                  <p *ngIf="product.discountprices?.length === 0 && host.prices.length === 1">
                    <span class="font-weight-bold">{{host.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                    <span>&nbsp;/&nbsp;{{host.prices[0].nameTranslated}}</span>
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(host.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
                  </p>
                </ng-container>
              </p>

              <!-- If there are multiple prices available, show select component -->
          <ng-container *ngIf="product.prices?.length > 1">
            <ng-select [items]="product.prices"
                [searchable]="false"
                [clearable]="false"
                bindValue="id"
                bindLabel="name"
                (change)="setPricingPeriod($event)"
                formControlName="pricingPeriod"
                i18n-placeholder="Odaberi ciklus plaćanja"
                placeholder="Odaberi ciklus plaćanja">
              <ng-template ng-label-tmp let-item="item">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="product.discountprices && getDiscountPricing(product.discountprices, product.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                  <span>
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{getPricing(product.prices, product.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(product.prices, product.pricingPeriodId, 'nameTranslated')}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(product.prices, product.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-container>

                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span>{{getPricing(product.prices, product.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(product.prices, product.pricingPeriodId, 'nameTranslated')}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(product.prices, product.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="product.discountprices && getDiscountPricing(product.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                  <span class="font-weight-bold">
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-container>

                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
            </ng-select>
          </ng-container>
            </div>
          </div>
          <p><span class="font-weight-bold mr-2" i18n>Opcije:</span> {{getDescription(product)}}</p>
        </div>

        <ng-container *ngIf="product?.showdomainoptions === 1">
          <div class="bg-white mb-3 p-3 shadow">
            <div class="form-check">
              <input id="domainNew" type="radio" formControlName="domainType" value="register" class="form-check-input"/>
              <label for="domainNew" class="form-check-label" i18n>Želim registrirati novu domenu</label>
            </div>
            <p i18n>Ukoliko nemaš domenu ili jednostavno trebaš novu, odaberi ovu opciju.</p>
          </div>

          <div *ngIf="f.domainType.value !== 'register' else domainPicker"></div>

          <div class="bg-white mb-3 p-3 shadow">
            <div class="form-check">
              <input id="domainTransfer" type="radio" formControlName="domainType" value="transfer" class="form-check-input"/>
              <label for="domainTransfer" class="form-check-label" i18n>Želim prebaciti postojeću domenu s drugog registra na MyDataKnox</label>
            </div>
            <p><span i18n>Ako već posjeduješ domenu registriranu kod neke druge tvrtke te istu želiš prebaciti na MyDataKnox, odaberi ovu opciju.</span><br><span i18n>Kod samog transfera domene bitno je znati nekoliko stvari:</span></p>
              <ol>
                <li i18n>Domena prije transfera mora biti otključana za prijenos na trenutnom registru</li>
                <li i18n>Moraš imati EPP/SECRET kod za inicijalizaciju transfera (osim .hr i .com.hr domena)</li>
                <li i18n>Domena će nakon transfera automatski biti produljena za dodatnih godinu dana</li>
                <li i18n>Besplatne .HR domene se <b>ne transferiraju</b> jer tada postaju naplatne!</li>
              </ol>
          </div>

          <div *ngIf="f.domainType.value !== 'transfer' else domainPicker"></div>

          <!--    Domain picker should look the same for register and transfer type, all except filter in domain search      -->
          <ng-template #domainPicker>
            <div class="pl-3 pl-lg-5 mb-3" *ngIf="f.domainType.value === 'register' || f.domainType.value === 'transfer'">
              <app-product-domain [filter]="f.domainType.value === 'register' ? 'available' : 'unavailable'" [isIncluded]="true"></app-product-domain>

              <div class="form-group" *ngIf="domains?.length > 0">
                <label for="domain" class="form-check-label" i18n>Odaberi naziv glavne domene za hosting</label>
                <ng-select [items]="domains"
                           (change)="checkDomain(changeDomainProduct)"
                           formControlName="domain"
                           bindValue="name"
                           bindLabel="name"
                           i18n-placeholder="Odaberi glavnu domenu"
                           placeholder="Odaberi glavnu domenu">
                  <ng-template ng-label-tmp let-item="item">
                    <span>{{getSelectedDomain('name') }}</span>&nbsp;-&nbsp;<ng-container *ngIf="getSelectedDomain('name')">({{getSelectedDomain('pricingPeriodId')}}&nbsp;<span i18n>godina</span>)</ng-container>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <span [ngClass]="{'font-weight-bold': item.cartProductId !== undefined}"><span >{{item.name }}</span>&nbsp;-&nbsp;({{item.pricingPeriodId}}&nbsp;<span i18n>godina</span>)</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </ng-template>

          <div class="bg-white mb-3 p-3 shadow">
            <div class="form-check">
              <input id="domainOwn" type="radio" formControlName="domainType" value="renew" class="form-check-input"/>
              <label for="domainOwn" class="form-check-label" i18n>Imam vlastitu domenu i osobno ću izmjeniti nameservere</label>
            </div>
            <p>
              <span i18n>Ako već posjeduješ domenu i želiš ju iskoristiti za povezivanje s hosting paketom, odaberi ovu opciju.</span>
              <br>
              <span i18n>Kako bi domena prikazivala sadržaj s hosting paketa, potrebno je nakon aktivacije hosting paketa izmijeniti DNS podatke za domenu tako da upućuje na MyDataKnox nameservere.</span>
              <br>
              <span i18n>Detaljne upute o usmjerenju naći ćeš u bazi znanja među često postavljanim pitanjima.</span>
            </p>
          </div>

          <div class="pl-5 mb-3" *ngIf="f.domainType.value === 'renew'">
            <div class="form-group">
              <label for="domain" class="col-form-label" i18n>Upiši naziv domene:</label>
              <input id="domain" type="text" formControlName="domain" placeholder="domena.com" class="form-control"/>
            </div>
          </div>

          <div *ngIf="submitted && f.domain.errors" class="alert alert-danger">
            <div *ngIf="f.domain.errors.required" i18n>Unos domene je obavezan</div>
            <div *ngIf="f.domain.errors.minlength" i18n>Naziv domene mora imati najmanje 3 znaka</div>
            <div *ngIf="f.domain.errors.pattern" i18n>Neispravan format domene</div>
          </div>
        </ng-container>
      </div>

      <div class="container-fluid">
        <div class="row">
          <a routerLink="./" [queryParams]="{groupSet: 'whost'}" class="btn bg-color1 rounded-light col-sm-12 col-md-4 mb-3 mb-md-0" type="submit" i18n>+ Dodaj novi hosting</a>
          <button class="btn btn-green rounded-light col-sm-12 col-md-4 offset-md-4" type="submit" i18n>Nastavi</button>
        </div>
      </div>

      <ng-template #changeDomainProduct>
        <div class="modal-header d-flex justify-content-between">
          <h3>Domena povezana</h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p i18n>Domena <span class="font-weight-bold">{{ getSelectedDomain('name')}}</span> je već povezana na proizvod</p>
          <p class="mb-3" *ngIf="connectedCartItem"><a routerLink="./product" [queryParams]="{'cid': connectedCartItem.cartId}"></a> {{connectedCartItem.name}}</p>
          <p class="fsmaller" i18n>Nastavkom ukidaš vezu na prijašnji proizvod!</p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-primary rounded-light" *ngIf="!submitted" aria-label="Close" (click)="modalRef.hide()" i18n>Zatvori</button>
        </div>
      </ng-template>
    </form>
  </ng-container>

  <ng-template #chooseProduct>
    <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-0" *ngIf="!hideTitle">
      <a routerLink="/cart" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
         class="color-white btn btn-outline-light rounded-light mr-3"><</a>
      <h3 class="color-white">{{getGroupName()}}</h3>
    </div>

    <div class="container-fluid bg-white">
      <div class="row bd-bottom pt-2 pb-2" *ngFor="let host of hosts" [ngClass]="host.added ? 'bg-color8' : ''">
        <div class="col-sm-12 col-lg-7 mb-3 mb-lg-0">
          <p class="font-weight-bold">{{host.name}}</p>
          <p class="fsmall">{{getDescription(host)}}</p>
        </div>

        <div class="col-sm-6 col-lg-2 mb-3 mb-sm-0">
          <!-- If there's only one type of prices, show a simple pricing -->
          <ng-container *ngIf="host.prices.length === 1">
            <!-- Check if there are discounts available -->
            <ng-container *ngIf="host.discountprices?.length === 1">
              <span class="font-weight-bold">
                {{host.discountprices?.[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
              </span>
              <span class="text-danger line-through font-italic">
                {{host.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
              </span>
              <span>&nbsp;/&nbsp;{{host.discountprices?.[0].nameTranslated}}</span>
            </ng-container>

            <!-- If there are not discounts available, show regular price -->
            <ng-container *ngIf="(!host.discountprices || host.discountprices?.length === 0) && host.prices.length === 1">
              <p>
                <span class="font-weight-bold">{{host.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{host.prices[0].nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(host.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
              </p>
            </ng-container>
          </ng-container>

          <!-- If there are multiple prices available, show select component -->
          <ng-container *ngIf="host.prices?.length > 1">
            <ng-select [items]="host.prices"
                      [searchable]="false"
                      [clearable]="false"
                      class="w-100"
                      bindValue="id"
                      bindLabel="name"
                      [(ngModel)]="host.pricingPeriodId"
                      i18n-placeholder="Odaberi period"
                      placeholder="Odaberi period">
              <ng-template ng-label-tmp let-item="item">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="host.discountprices && getDiscountPricing(host.discountprices, host.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                  <span>
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{getPricing(host.prices, host.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(host.prices, host.pricingPeriodId, 'nameTranslated')}};</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(host.prices, host.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-container>

                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span>{{getPricing(host.prices, host.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(host.prices, host.pricingPeriodId, 'nameTranslated')}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(host.prices, host.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="host.discountprices && getDiscountPricing(host.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                  <span class="font-weight-bold">
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-container>

                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
            </ng-select>
          </ng-container>
        </div>

        <div class="col-sm-6 col-lg-3">
          <button *ngIf="!host.stockcontrol || (host.stockcontrol && host.stocklevel > 0)" class="btn btn-bordered rounded-light btn-sm btn-green w-100 h-select" [ngClass]="host.added ? 'btn-active' : ''" (click)="setProduct(host)">
            <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-3"></svg-icon>
            <span *ngIf="!host.added; else removeCartItem;" i18n>Dodaj u košaricu</span>
            <ng-template #removeCartItem>
              <span i18n>Ukloni iz košarice</span>
            </ng-template>
          </button>

          <button *ngIf="host.stockcontrol && host.stocklevel <= 0"
                  [disabled]="true"
                  class="btn btn-bordered rounded-light btn-sm w-100 h-select">
            <span i18n>Trenutno nedostupno</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="p-3 text-center">
  <app-loader *ngIf="loading.product"></app-loader>
</div>
