import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {interval, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {AuthenticationService, PrintService} from '../../../_services';
import {TicketService} from '../ticket.service';
import {Ticket} from '../ticket';
import {Client} from '../../../_models';

@Component({
  templateUrl: './ticket-view.component.html'
})
export class TicketViewComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  ticket: Ticket;
  loading = false;
  hiddenReplies = false;
  reply = false;
  modalRef: BsModalRef;
  user: Client;
  ticketTimer = interval(30000);
  loadingCount = {
    ticket: 0
  };
  replyAdmin = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: TicketService,
    private modalService: BsModalService,
    public printService: PrintService,
    private auth: AuthenticationService
  ) {
    this.subscription.add(
      this.auth.currentUser.subscribe((user) => {
        if (user) {
          this.user = user;
        }
      })
    );
  }

  ngOnInit() {
    this.loadTicket();
    const loadTicketSub = this.ticketTimer.subscribe(val => {
      this.loadTicket();
    });
    this.subscription.add(loadTicketSub);
  }

  loadTicket() {
    this.loading = true;
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.service.getTicket(params.get('id'), this.route.snapshot.queryParamMap.get('c'), this.loadingCount.ticket === 0);
      }),
      take(1)
    )
      .subscribe((ticket) => {
          this.loading = false;
          this.loadingCount.ticket += 1;
          if (ticket === null) {
            this.router.navigate(['/support/tickets']);
            return false;
          }
          this.ticket = ticket;
          if (this.ticket.replies.length > 0) {
            for (const reply of this.ticket.replies) {
              reply.message = reply.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
              if (reply.admin !== '') {
                this.replyAdmin = reply.admin;
              }
            }
          }
          if (ticket.clientunread === 1) {
            this.setAsRead();
          }
          this.hiddenReplies = this.ticket.replies.length > 2;
        }
      );
  }

  setAsRead() {
    this.subscription.add(
      this.service.setAsRead(this.ticket)
        .subscribe(res => {
          this.ticket.clientunread = 0;
        })
    )
  }

  getHiddenNb(): number {
    return this.ticket.replies.length - 2;
  }

  closeTicket(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  printTicket() {
    this.printService.printDocument('ticket', [this.ticket.tid.toString()]);
    this.printService.onDataReady();
  }

  confirmClose(): void {
    this.modalRef.hide();
    this.ticket.status = 'Closed';
    this.loading = true;
    const updateTicketSub = this.service.update(this.ticket).subscribe(res => {
      this.loading = false;
      return this.router.navigate(['/support/tickets']);
    });
    this.subscription.add(updateTicketSub);
  }

  declineClose(): void {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
