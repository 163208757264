export class Contact {
  id: number;
  userid: number;
  firstname: string;
  lastname: string;
  companyname: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  phonenumber: string;
  telephoneNumber: any;
  // tslint:disable-next-line:variable-name
  tax_id: string;
  subaccount: boolean | string;
  password: string;
  permissions: string;
  domainemails: boolean;
  generalemails: boolean;
  invoiceemails: boolean;
  productemails: boolean;
  supportemails: boolean;
  affiliateemails: boolean;
  pwresetkey: string;
  createdAt: string;
  updatedAt: string;
  pwresetexpiry: string;
  action: string;
  contactid: number;
  persontype: string;
  qrlink: string;
  twofa: boolean;
}
