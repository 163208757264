import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AngularSvgIconModule} from 'angular-svg-icon';
import {NgSelectModule} from '@ng-select/ng-select';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgxSliderModule} from '@m0t0r/ngx-slider';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {CartComponent} from './cart.component';
import {CartRoutingModule} from './cart-routing.module';
import {LoaderModule} from '../loader/loader.module';
import {StepProductComponent} from './steps/product';
import {StepOptionsComponent} from './steps/options';
import {StepUserComponent} from './steps/user';
import {StepPaymentComponent} from './steps/payment';
import {StepConfirmationComponent} from './steps/confirmation';
import {CartItemsComponent} from './cart-items';
import {ProductDomainComponent} from './products/domain';
import {ProductWebHostingComponent} from './products/web-hosting';
import {ProductVpsComponent} from './products/vps';
import {ProductDedicatedServerComponent} from './products/dedicated-server';
import {ProductBackupComponent} from './products/backup';
import {LoginModule} from '../login';
import {StepMiddleComponent} from './steps/middle';
import {ProfileModule} from '../profile';
import {StepOverviewComponent} from './steps/overview';
import {CartProgressComponent} from './cart-progress';
import {ProductDomainNewComponent} from './products/domain/new';
import {ExternalUrlModule} from '../shared/external-url';
import {ProductSslComponent} from './products/ssl';
import {ProductLicenceGroupComponent} from './products/licence-group';
import {StepOptionsDomainsComponent} from './steps/options-domains';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';

@NgModule({
  imports: [
    CommonModule,
    CartRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    AngularSvgIconModule,
    LoaderModule,
    LoginModule,
    FormsModule,
    ProfileModule,
    AccordionModule,
    NgxSliderModule,
    TooltipModule,
    ExternalUrlModule,
    FontAwesomeModule,
    PasswordStrengthMeterModule
  ],
  declarations: [
    CartComponent,
    StepProductComponent,
    StepOptionsComponent,
    StepUserComponent,
    StepPaymentComponent,
    StepConfirmationComponent,
    CartItemsComponent,
    ProductDomainComponent,
    ProductWebHostingComponent,
    ProductVpsComponent,
    ProductDedicatedServerComponent,
    ProductBackupComponent,
    StepMiddleComponent,
    CartProgressComponent,
    StepOverviewComponent,
    ProductDomainNewComponent,
    ProductSslComponent,
    ProductLicenceGroupComponent,
    StepOptionsDomainsComponent
  ],
  exports: [
    CartItemsComponent,
    CartComponent
  ]
})
export class CartModule {}
