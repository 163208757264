import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Subscription} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {CustomValidator} from '../../../_components';
import {Contact} from '../contact';
import {AlertService, AuthenticationService} from '../../../_services';
import {ContactService} from '../contact.service';
import {SystemService} from '../../../_services/system.service';
import {Client} from "../../../_models";

@Component({
  templateUrl: 'contact-edit.component.html'
})
export class ContactEditComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @ViewChild('cancelDisableSubaccount') confirmSubaccountModal: TemplateRef<any>;
  user: Contact;
  parentUser: Client;
  updateForm: FormGroup;
  loading = false;
  submitted = false;
  modalRef: BsModalRef;
  // At least one lowercase, one uppercase, one special character, and 8-64 valid characters
  public passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\$%@?+\-`!;,.:#*()_\[\]\{\}])[\w\$%@?+\-`!;,.:#*()_\[\]\{\}]{8,64}$/;
  public isContactSameAsParent = false;

  constructor(
    private service: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private systemService: SystemService,
    protected authenticationService: AuthenticationService
  ) {
    this.parentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.service.getById(parseInt(params.get('id'), 10)))
    ).subscribe((user: Contact) => {
      this.loading = false;
      if (user === undefined) {
        this.alertService.success($localize`Nepostojeći kontakt`, true);
        this.router.navigate(['/profile/contacts']);
      }
      this.user = user;

      this.isContactSameAsParent = this.parentUser.id === this.user.id;

      const formData = {
        contactid: [this.user.id, Validators.required],
        email: [{value: user.email, disabled: this.disableInput('email')}, [Validators.required, Validators.email]],
        firstname: [{value: user.firstname, disabled: this.disableInput('firstname')}, Validators.required],
        lastname: [{value: user.lastname, disabled: this.disableInput('lastname')}, Validators.required],
        companyname: [{value: user.companyname, disabled: this.disableInput('companyname')}],
        address1: [{value: user.address1, disabled: this.disableInput('address1')}],
        address2: [{value: user.address2, disabled: this.disableInput('address2')}],
        city: [{value: user.city, disabled: this.disableInput('city')}],
        state: [{value: user.state, disabled: this.disableInput('state')}],
        postcode: [{value: user.postcode, disabled: this.disableInput('postcode')},
          [Validators.minLength(2)]],
        country: [{value: user.country, disabled: this.disableInput('country')}],
        phonenumber: [{value: user.phonenumber, disabled: this.disableInput('phonenumber')}],
        telephoneNumber: [{value: user.phonenumber, disabled: this.disableInput('phonenumber')}],
        tax_id: [{value: user.tax_id, disabled: this.disableInput('tax_id')},
          [Validators.minLength(5)]],
        subaccount: [{value: user.subaccount, disabled: this.disableInput('subaccount')}],
        generalemails: [{value: user.generalemails, disabled: this.disableInput('generalemails')}],
        productemails: [{value: user.productemails, disabled: this.disableInput('productemails')}],
        domainemails: [{value: user.domainemails, disabled: this.disableInput('domainemails')}],
        invoiceemails: [{value: user.invoiceemails, disabled: this.disableInput('invoiceemails')}],
        supportemails: [{value: user.supportemails, disabled: this.disableInput('supportemails')}],
        password2: [null,
          [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(this.passPattern)]],
      };

      if (this.isContactSameAsParent) {
        formData['twofa'] = [{value: this.parentUser.twofa, disabled: this.disableInput('twofa')}];
      }

      this.updateForm = this.formBuilder.group(formData);
    });
  }

  getBackLink() {
    if (this.user.subaccount) {
      return '/profile/details';
    } else {
      return '../';
    }
  }

  generateRootPassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern.source);
    this.updateForm.patchValue({password2: newPass});
  }

  togglePassRequired(required) {
    if (required) {
      this.updateForm.get('password2').setValidators(
        [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(this.passPattern)]);
    } else {
      this.updateForm.get('password2').setValidators(
        [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(this.passPattern)]);
    }
  }

  onSubmit(confirm: boolean = false) {
    this.submitted = true;
    const telNum = this.updateForm.controls.telephoneNumber.value !== null ?
      this.updateForm.controls.telephoneNumber.value.internationalNumber : '';
    this.updateForm.patchValue({phonenumber: telNum});

    if (this.updateForm.invalid) {
      return;
    }
    // Re-enable country control to be able to send it in update request
    this.updateForm.get('country').enable();
    const data = this.updateForm.value;
    let permissions: string[] = [];
    for (const key in data.permissions) {
      if (data.permissions.hasOwnProperty(key)) {
        if (data.permissions[key]) {
          permissions.push(key);
        }
      }
    }

    if (!data.subaccount && this.user.subaccount) {
      permissions = [];
      if (!confirm) {
        this.modalRef = this.modalService.show(this.confirmSubaccountModal, {class: 'modal-sm'});
        return;
      }
      this.modalRef.hide();
    }
    data.permissions = permissions.join();

    // Check form against forbidden fields and reset their field (set from user variables)
    for (const key in data.value) {
      if (data.value.hasOwnProperty(key) && this.disableInput(key) && data.value[key] !== this.user[key]) {
        data.value[key] = this.user[key];
      }
    }

    // Hotfix for some whmcs stupidity... False can only be sent as a string
    data.generalemails = !data.generalemails ? 'false' : data.generalemails;
    data.domainemails = !data.domainemails ? 'false' : data.domainemails;
    data.invoiceemails = !data.invoiceemails ? 'false' : data.invoiceemails;
    data.productemails = !data.productemails ? 'false' : data.productemails;
    data.supportemails = !data.supportemails ? 'false' : data.supportemails;

    const updateSub = this.service.update(data)
      .subscribe(response => {
          if (response !== null) {
            this.alertService.success($localize`Izmjene su uspješno izvršene`, true);
            this.router.navigate(['/profile/contacts']);
          }

          if (this.isContactSameAsParent) {
            this.parentUser.twofa = this.updateForm.value.twofa;
            this.authenticationService.updateUser(this.parentUser);
          }
        },
        error => {
          this.alertService.error(error);
        });
    this.subscription.add(updateSub);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  delete(user: Contact) {
    this.loading = true;
    this.service.delete(user)
      .pipe(first()).subscribe(
      tmpUser => {
        this.alertService.success($localize`Kontakt je uspješno uklonjen`);
        this.router.navigate(['/profile/contacts']);
      },
      error => {
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Checks against forbidden fields array should a certain field be disabled or not.
   * Forbidden field is permitted only if value doesn't exist (is null)
   * @param fieldName string
   */
  disableInput(fieldName: string) {
    return this.parentUser.forbiddenFields !== undefined &&
      this.parentUser.forbiddenFields.indexOf(fieldName) > -1 && this.user[fieldName] !== null;
  }
}
