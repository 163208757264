import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Options} from '@m0t0r/ngx-slider';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {ProductGroup} from '../../../_models/product-group';
import {Currency} from '../../../_models/currency';
import {Product} from '../../../_models/product';
import {CartService} from '../../cart.service';
import {ProductService} from '../../../_services/product.service';
import {CustomValidator} from '../../../_components';
import {ProductConfigOption} from '../../../_models/product-config-option';
import {CartOrderItem} from '../../cart-order-item';
import {SystemService} from '../../../_services/system.service';
import {ProductDomainItem} from '../../product-domain-item';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-licence-group',
  templateUrl: './product-licence-group.component.html'
})
export class ProductLicenceGroupComponent implements OnInit, OnDestroy {
  @Input() productGroup: ProductGroup;
  @Input() cartCurrency: Currency;
  @Input() configOptions: ProductConfigOption[];
  private subscription: Subscription = new Subscription();
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  productId: number;
  licenceGroupForm: FormGroup;
  licenceGroupProducts: Product[];
  domains: ProductDomainItem[] = [];
  submitted = false;
  loading = {
    product: false
  };
  product: Product;
  connectedCartItem: CartOrderItem;
  shownFields = [];
  passPattern = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,16}$';
  returnToOptionCid: number;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private systemService: SystemService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.licenceGroupForm = this.formBuilder.group({
      cartId: [null],
      quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
      pricingPeriod: [null, Validators.required],
      totalPrice: [null, Validators.required],
      regPeriod: [null],
      config: new FormArray([]),
      customfields: new FormArray([]),
      domain: null,
      domainType: 'register'
    });

    const domainsSub = this.cartService.domainItems$
      .subscribe(domains => {
        this.domains = domains;
        if (domains !== null && this.f.domainType.value === 'register') {
          const firstFreeDomain = domains.find(x => x.cartProductId === undefined);
          if (firstFreeDomain) {
            this.licenceGroupForm.patchValue({domain: firstFreeDomain.name});
          }
        }
      });
    this.subscription.add(domainsSub);
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    if (!this.productGroup) {
      return false;
    }
    if (!this.cartCurrency) {
      this.subscription.add(
        this.cartService.getCartCurrency()
          .subscribe(currency => {
            this.cartCurrency = currency;
          })
      );
    }
    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        this.c.clear();
        this.cu.clear();
        this.productId = (params.pid !== undefined) ? parseInt(params.pid, 10) : null;
        const cartId = (params.cid !== undefined) ? parseInt(params.cid, 10) : null;
        this.returnToOptionCid = (typeof params.returnOption !== 'undefined') ? parseInt(params.returnOption, 10) : null;

        if (this.productId) {
          for (const cfgk in params) {
            if (params.hasOwnProperty(cfgk) && cfgk.indexOf('cfg') > -1) {
              let cfgId = cfgk.replace('cfg[', '');
              cfgId = cfgId.replace(']', '');
              const configOption = new ProductConfigOption();
              configOption.configoptionid = parseInt(cfgId, 10);
              configOption.value = parseInt(params[cfgk], 10);
              this.configOptions.push(configOption);
            }
          }

          this.loading.product = true;
          const prodSub = this.productService.getProductById(this.productId, this.cartCurrency.code)
            .subscribe((product) => {
              this.loading.product = false;
              this.product = product;
              if (this.product.showdomainoptions === 1) {
                this.licenceGroupForm.get('domain').setValidators([
                  Validators.required, Validators.minLength(3),
                  Validators.pattern('^(?:[a-žA-Ž0-9](?:[a-žA-Ž0-9-]{0,61}[a-žA-Ž0-9]\\.)?)+[a-žA-Ž0-9][a-žA-Ž0-9-]{0,61}[a-žA-Ž0-9]$')
                ]);
                this.licenceGroupForm.get('domainType').setValidators([Validators.required]);
              }
              if (!this.productHaveCustomConfigOptionsAndFields(product)) {
                this.setProduct(product);
                return;
              } else {
                const licencePrice = (!product.pricingPeriodId && product.prices.length > 0) ? product.prices[0] : null;
                if (licencePrice) {
                  product.pricingPeriodId = licencePrice.id;
                }
                this.licenceGroupForm.patchValue({pricingPeriod: product.pricingPeriodId});
                this.licenceGroupForm.patchValue({totalPrice: this.getPriceForPeriodId(product, product.pricingPeriodId)});
              }

              this.product.configoptions.forEach(option => {
                let defVal = this.productService.getValueForOption(option, 'min');
                const ticks = this.productService.getOptionsForCFG(option);
                const slider = this.productService.getValueForOption(option, 'name', defVal);
                defVal = 1; // No default value provided for HE in config options so default is 1
                const defOption = option.options[0];
                const price = this.getPriceForOption(defOption);
                if (this.configOptions.length > 0) {
                  const defaultOption = this.configOptions.find(x => x.configoptionid === option.id);
                  defVal = (defaultOption) ? defaultOption.value : defVal;
                }
                this.addOptionItem(option.id, defVal, option.name, slider, price, ticks);
              });

              this.product.customfields.forEach(option => {
                if (option.adminonly === '' && option.showorder === 'on') {
                  this.addCustomFieldItem(option.id, null, option.name, option.options.split(','), option.type);
                }
              });

              if (cartId !== null) {
                this.setDefaultFromCart(cartId);
              }

              this.updateOptionPrices();
            });
          this.subscription.add(prodSub);
        } else {
          this.loading.product = true;
          const subA = this.productService.getProducts(null, null, this.productGroup.id, this.cartCurrency.code, true)
            .subscribe((products) => {
              this.loading.product = false;
              this.licenceGroupProducts = products;
              this.setPricingDefaults();
            });
          this.subscription.add(subA);
        }
      });
    this.subscription.add(routerQidSub);
  }

  generatePassword(customField: any) {
    const newPass = this.systemService.genRandomPattern(this.passPattern);
    customField.get('value').setValue(newPass);
  }

  get f() {
    return this.licenceGroupForm.controls;
  }

  get c() {
    return this.f.config as FormArray;
  }

  get cu() {
    return this.f.customfields as FormArray;
  }

  setDefaultFromCart(cartId: number) {
    const cartItem: CartOrderItem = this.cartService.getCartItemByCartId(cartId);
    if (cartItem) {
      this.licenceGroupForm.patchValue({cartId: cartItem.cartId});
      this.licenceGroupForm.patchValue({rootPassword: cartItem.rootPassword});
      this.licenceGroupForm.patchValue({pricingPeriod: cartItem.pricingPeriodId});
      this.licenceGroupForm.patchValue({quantity: cartItem.quantity});
      cartItem.config.forEach(cfg => {
        const cfgControl = this.c.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cfgControl.get('value').setValue(cfg.value);
      });

      cartItem.customfields.forEach(cfg => {
        const cuControl = this.cu.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cuControl.get('value').setValue(cfg.value);
      });
    }
  }

  checkDomain() {
    const domain = this.domains.find(x => x.name === this.f.domain.value);
    if (domain !== undefined && typeof domain.cartProductId !== 'undefined') {
      this.connectedCartItem = this.cartService.getCartItemByCartId(domain.cartProductId);
    } else {
      this.connectedCartItem = null;
    }
  }

  getSelectedDomain(field: string = null) {
    let selectedDomain;
    if (this.domains && this.domains.length > 0) {
      selectedDomain = this.domains.find(x => x.name === this.f.domain.value);
    }
    if (!selectedDomain) {
      return;
    }
    return (field && selectedDomain) ? selectedDomain[field] : selectedDomain;
  }

  updateOptionPrices() {
    let totalPrice = 0;

    const basePrice = this.getCurrentPeriodPrice('value');
    this.licenceGroupForm.patchValue({basePrice});
    totalPrice += this.f.quantity.value * basePrice;
    this.licenceGroupForm.patchValue({basePrice});
    const setupPrice = this.getSetupPrice('setupfee');
    this.licenceGroupForm.patchValue({setupPrice});

    this.c.controls.forEach(cntrl => {
      const productConfig = this.product.configoptions.find(x => x.id === cntrl.get('id').value);
      if (productConfig === undefined) {
        return;
      }
      const selectedValue = cntrl.get('value').value;
      const option = productConfig.options[0];
      const price = this.f.quantity.value * selectedValue * this.getPriceForOption(option);
      totalPrice += price;
      cntrl.get('price').setValue(price);
      cntrl.get('slider').setValue(selectedValue);
    });

    this.licenceGroupForm.patchValue({totalPrice});
  }

  getCurrentPeriodPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    if (field) {
      return currentPrice !== undefined ? currentPrice[field] : 0;
    }
    return currentPrice !== undefined ? currentPrice : 0;
  }

  getSetupPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPeriodPrice = this.getCurrentPeriodPrice();
    const feeSuffix = currentPeriodPrice.name !== undefined ? currentPeriodPrice.name.charAt(0) : '';
    const productCurrencyFee = this.product.pricing[this.cartCurrency.code];
    if (field) {
      const fieldValue = parseFloat(productCurrencyFee[feeSuffix + field]);
      return (fieldValue < 0) ? 0 : fieldValue;
    }
    return productCurrencyFee;
  }

  addOptionItem(id: number = null, value: number = null, label: string = null, slider: string = null, price: number = null, ticks: Options)
    : void {
    this.c.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      slider: [slider],
      price: [price],
      ticks
    }));
  }

  addCustomFieldItem(id: number = null, value: string = null, label: string = null, options: string[], type: string = 'text'): void {
    const passPattern = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])([^\\s]){8,16}$';
    if (type === 'password') {
      value = this.systemService.genRandomPattern(passPattern);
    }
    this.cu.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, type === 'password' ?
        [
          Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(passPattern)
        ] : Validators.required],
      label: [label],
      options: [options],
      type: [type]
    }));
    if (type === 'password') {
      this.shownFields.push({id: true}); // Changed to show password by default since it is automatically generated
    } else {
      this.shownFields.push({id: true});
    }
  }

  setPricingDefaults(): void {
    this.productService.setPricingDefaults(this.licenceGroupProducts);
  }

  getDescription(licenceProduct: Product): string {
    const description = this.productService.getDescription(licenceProduct);
    if (description === '') {
      return this.productService.getDescription(licenceProduct, true);
    }
    return description;
  }

  getName(licenceProduct: Product): string {
    return this.productService.getName(licenceProduct);
  }

  getGroupName(): string {
    return this.productService.getGroupNameByTag(this.productGroup.tag);
  }

  getPricing(prices: any, selectedId: number, field: string): number | string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  getPriceForPeriodId(licenceProduct: Product, pricingPeriodId: number): number {
    return this.productService.getPriceForPeriodId(licenceProduct, pricingPeriodId);
  }

  getPriceForOption(option: any) {
    const prices = option.pricing[this.cartCurrency.code];
    const pricingPeriod = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    const price = pricingPeriod !== undefined ? parseFloat(prices[pricingPeriod.name]) : 0;
    return (price <= 0) ? 0 : price;
  }

  updateCfgOption(id, cfg) {
    const labelSuffix = cfg.controls.slider.value.toString().replace(/\d+/, '');
    cfg.controls.slider.setValue(id.value.toString() + labelSuffix);
    this.updateOptionPrices();
  }

  setCustomFieldValue(value: string, customFieldControl) {
    customFieldControl.get('value').setValue(value);
  }

  setProduct(licenceProduct: Product): void | boolean {
    this.product = licenceProduct;
    if (this.productHaveCustomConfigOptionsAndFields(licenceProduct)) {
      // If product is hosted exchange, than go to the url with specified product
      this.router.navigate(['/cart/product'], {queryParams: {gid: licenceProduct.gid, pid: licenceProduct.pid}});
      if (!licenceProduct.pricingPeriodId) {
        this.updateCart();
        return false;
      }
    }

    const licencePrice = (!licenceProduct.pricingPeriodId && licenceProduct.prices.length > 0) ? licenceProduct.prices[0] : null;
    if (licencePrice) {
      licenceProduct.pricingPeriodId = licencePrice.id;
    }
    this.licenceGroupForm.patchValue({pricingPeriod: licenceProduct.pricingPeriodId});
    this.licenceGroupForm.patchValue({totalPrice: this.getPriceForPeriodId(licenceProduct, licenceProduct.pricingPeriodId)});
    // If product is NOT hosted exchange, than go to the next step
    if (!this.productHaveCustomConfigOptionsAndFields(licenceProduct)) {
      this.updateCart();
    }
  }

  /**
   * Method checks if product has custom options or fields to display on second step.
   * @param product
   * @private
   */
  private productHaveCustomConfigOptionsAndFields(product: Product): boolean {
    let found = false;
    if (product.configoptions.length > 0) {
      found = true;
    }
    if (product.customfields.length > 0) {
      product.customfields.forEach(option => {
        if (option.adminonly === '') {
          found = true;
        }
      })
    }
    return found;
  }

  updateCart() {
    this.submitted = true;
    if (this.licenceGroupForm.invalid) {
      return false;
    }
    const name = this.getName(this.product);
    const desc = this.getDescription(this.product);
    let cartItem = this.cartService.generateCartItem(this.product, this.productGroup, name, desc, this.licenceGroupForm.value);
    cartItem.domain = this.product.showdomainoptions === 1 ? this.licenceGroupForm.value.domain : '';
    cartItem = this.cartService.addOrderItem(cartItem);
    if (this.licenceGroupForm.value.domainType === 'register' && cartItem.cartId !== undefined) {
      let domain = this.getSelectedDomain();
      if (domain) {
        domain = this.cartService.domainInCart(domain);
        domain.cartProductId = cartItem.cartId;
        this.cartService.addDomain(domain);
      }
    }
    this.licenceGroupForm.patchValue({cartId: cartItem.cartId});
    this.submitted = false;
    return this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProductTranslatedName(prod: Product) {
    if (prod.translated_product_name[this.localeId] !== undefined) {
      return prod.translated_product_name[this.localeId];
    }
    return prod.name;
  }
}
