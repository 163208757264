<div class="container-fluid">
  <div class="row pt-1 pt-md-5 bg-background">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 box p-2 p-md-3 p-lg-4">
      <h5 class="font-weight-bold mb-3 bd-bottom pb-3" i18n>Aktivacija korisnika</h5>
      <div class="d-flex flex-center p-3">
        <fa-icon *ngIf="loading" [icon]="faSpinner" [spin]="true"></fa-icon>
        <div class="alert alert-danger" *ngIf="error" i18n>Greška prilikom aktivacije.</div>
      </div>
    </div>
  </div>
</div>
