import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IClientAuthorizeCallbackData, ICreateOrderRequest, IPayPalConfig, ITransactionItem} from 'ngx-paypal';

import {Invoice} from '../invoice';
import {AlertService, AuthenticationService} from '../../../_services';
import {InvoiceService} from '../invoice.service';
import {Currency} from '../../../_models/currency';
import {Client} from '../../../_models';
import {PaymentMethod} from '../../../_models/payment-method';
import {SystemService} from '../../../_services/system.service';
import {PayMethod} from '../../../_models/pay-method';
import {APP_CONFIG, AppConfig} from '../../../app-config.module';
import {Location} from '@angular/common'

@Component({
  selector: 'app-bill-view',
  templateUrl: './bill-view.component.html'
})
export class BillViewComponent implements OnInit, OnDestroy {
  @ViewChild('paymentInProgressModal') paymentInProgress: TemplateRef<any>;
  private subscription: Subscription = new Subscription();
  invoice: Invoice;
  loading = {
    invoice: false,
    form: false,
    file: false,
    paymentMethods: false,
    currency: false,
    addingTransaction: false,
    credit: true
  };
  currentCurrency: Currency;
  companyData: string;
  refNumber: string;
  faEdit = faEdit;
  client: Client;
  hideCardlist = false;
  creditForm: FormGroup;
  submitted = false;
  modalRef: BsModalRef;
  paymentMethods: PaymentMethod[];
  payPalConfig ?: IPayPalConfig;
  eurCurrency: Currency;
  public displayHrk = false;
  private showSuccess: boolean;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private service: InvoiceService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private systemService: SystemService,
    private location: Location
  ) {
    this.client = this.authenticationService.currentUserValue;
    this.currentCurrency = this.authenticationService.currentCurrencyValue;
  }

  ngOnInit() {
    // Get updated credit ammount
    this.subscription.add(
      this.authenticationService.getUserDetails()
        .subscribe(user => {
            this.loading.credit = false;
            if (user) {
              this.client.credit = user.credit !== undefined ? user.credit : 0;
              this.authenticationService.updateUser(this.client);
              this.loadInvoice();
              this.loadPaymentMethods();
              // this.loadCurrenciesAndPaypalConfig();
            }
          }
        )
    );

    this.displayHrk = this.config.displayHrk;
  }

  private loadCurrenciesAndPaypalConfig() {
    if (this.eurCurrency === undefined) {
      this.loading.currency = true;
      const eurCurrency = this.systemService.getCurrencies()
        .pipe(take(1))
        .subscribe((curr) => {
          this.loading.currency = false;
          for (const currency of curr) {
            if (currency.code === 'EUR') {
              this.eurCurrency = currency;
              this.paypalConfig();
            }
          }
        });
    }
  }

  private paypalConfig() {
    this.payPalConfig = {
      currency: this.eurCurrency.code,
      clientId: this.config.paypalClientId,
      createOrderOnClient: (data: ICreateOrderRequest) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: this.eurCurrency.code,
              value: (Math.round(((this.invoice.total * (this.eurCurrency.rate > 0 ? this.currentCurrency.rate : 1)) + Number.EPSILON) * 100) / 100).toFixed(2),
              breakdown: {
                item_total: {
                  currency_code: this.eurCurrency.code,
                  value: (Math.round(((this.invoice.total * (this.eurCurrency.rate > 0 ? this.currentCurrency.rate : 1)) + Number.EPSILON) * 100) / 100).toFixed(2)
                }
              }
            },
            items: [this.getPayPalItem()]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'horizontal'
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        this.addTransactionToInvoice(data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      },
    };
  }

  /**
   * Adds pament from paypal to invoice
   * @param data PayPal transaction data
   */
  addTransactionToInvoice(data: IClientAuthorizeCallbackData) {
    this.loading.addingTransaction = true;
    let totalAmount = 0;
    let fees = 0;
    let description = '';
    if (data.hasOwnProperty('purchase_unit')) {
      for (const unit of data.purchase_units) {
        totalAmount += parseFloat(unit.amount.value);
        fees += parseFloat(unit.amount.breakdown.handling.value);
        fees += parseFloat(unit.amount.breakdown.insurance.value);
        fees += parseFloat(unit.amount.breakdown.shipping.value);
        description += unit.description;
      }
    }
    const addTranSub = this.service.addInvoicePayment(this.invoice, data.id, data.create_time, 'paypal', totalAmount, fees)
      .pipe(take(1))
      .subscribe(res => {
        this.loading.addingTransaction = false;
        if (res) {
          this.alertService.success($localize`Uplata je uspješno provedena`);
          this.loadInvoice();
        } else {
          this.alertService.error($localize`Došlo je do problema s transakcijom`);
        }
      });
    this.subscription.add(addTranSub);
  }

  invoicePaid() {
    this.modalRef.hide();
    this.loadInvoice();
  }

  private getPayPalItem(): ITransactionItem {
    const paypalItem: ITransactionItem = {
      name: "Payment for invoice " + this.invoice.id,
      quantity: "1",
      category: "DIGITAL_GOODS",
      unit_amount: {
        currency_code: this.eurCurrency.code,
        value: (
          Math.round(
            (this.invoice.total *
              (this.eurCurrency.rate > 0 ? this.currentCurrency.rate : 1) +
              Number.EPSILON) *
              100
          ) / 100
        ).toFixed(2),
      },
    };

    return paypalItem;
  }

  hideCardModal(hideModal: boolean) {
    this.hideCardlist = hideModal;
  }

  loadInvoice(hideModals: boolean = false) {
    this.loading.invoice = true;
    const id = +this.route.snapshot.paramMap.get('id');
    this.subscription.add(
      this.service.getById(id)
        .subscribe(
          (invoice: Invoice) => {
            if (!invoice || (invoice.userid !== this.client.id && invoice.userid !== this.client.userid)) {
              return this.router.navigate(['./accounting/bills']);
            }
            invoice.notes = invoice.notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
            this.invoice = invoice;
            this.loadCurrenciesAndPaypalConfig();
            this.companyData = this.invoice.payto_data.payto.replace(/(?:\r\n|\r|\n)/g, '<br>');
            this.refNumber = this.invoice.payto_data.refnumber;

            this.loading.invoice = false;
            if (this.invoice.status.toLowerCase() === 'unpaid') {
              this.createForm();
            }
            if (hideModals) {
              this.loading.addingTransaction = false;
              this.modalRef.hide();
            }
          },
          () => {
            this.router.navigate(['./accounting/bills']);
          })
    );
  }

  private loadPaymentMethods() {
    this.loading.paymentMethods = true;
    const payMethSub = this.systemService.getPaymentMethods()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.loading.paymentMethods = false;
          this.paymentMethods = res;
        }
      );
    this.subscription.add(payMethSub);
  }

  getInvoiceFile() {
    this.loading.file = true;
    const fileSub = this.service.getInvoiceFile(this.invoice.invoiceid)
      .subscribe(res => {
        this.loading.file = false;
        if (typeof res.invoicedata !== 'undefined') {
          this.downloadPDF(res.invoicedata, res.invoicefilename);
        }
      });
    this.subscription.add(fileSub);
  }

  downloadPDF(stream: string, filename: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/octet-stream;base64,${stream}`;
    downloadLink.download = filename;
    downloadLink.click();
  }

  updateInvoicePaymentMethod(payMethod) {
    const previousPayMethod = this.invoice.paymentmethod;
    this.invoice.paymentmethod = payMethod;
    this.loading.form = true;
    const updateSub = this.service.updateInvoicePaymentMethod(this.invoice.id, payMethod)
      .subscribe(res => {
        this.loading.form = false;
        if (res) {
          this.alertService.success($localize`Način plaćanja uspješno je promijenjen`);
        } else {
          this.alertService.error($localize`Došlo je do greške u promjeni načina plaćanja`);
          this.invoice.paymentmethod = previousPayMethod;
        }
      });
    this.subscription.add(updateSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createForm() {
    const amount = parseFloat(String(this.client.credit)) > parseFloat(String(this.invoice.balance)) ?
      parseFloat(String(this.invoice.balance)) : parseFloat(String(this.client.credit));

    this.creditForm = this.formBuilder.group({
      amount: [amount, [Validators.required, Validators.max(parseFloat(String(this.client.credit)))]]
    });
  }

  get f() {
    return this.creditForm.controls;
  }

  applyCredit() {
    this.submitted = true;
    if (!this.creditForm.valid) {
      return false;
    }
    this.loading.form = true;
    const amount = parseFloat(this.creditForm.controls.amount.value);
    const applyCreditSub = this.service.applyCreditToInvoice(this.invoice.invoiceid, amount, false)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.form = false;
        if (res !== null) {
          if (res.result === 'success') {
            this.alertService.success($localize`Kredit je uspješno primijenjen`, true);
            this.client.credit = parseFloat(String(this.client.credit)) - amount; // Reduce users credit balance by amount
            this.authenticationService.updateUser(this.client);
            this.loadInvoice();
          } else {
            this.alertService.error($localize`Dodavanje kredita na ponudu nije uspjelo!`);
          }
        } else {
          this.alertService.error($localize`Dodavanje kredita na ponudu nije bilo uspješno!`);
        }
      });
    this.subscription.add(applyCreditSub);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  /**
   * Make payment with selected clients card.
   * @param payMethod PayMethod
   */
  selectCard(payMethod: PayMethod) {
    this.openModal(this.paymentInProgress);
    this.loading.addingTransaction = true;
    if (payMethod.transaction_id === undefined) {
      const addTranSub = this.service.addRemoteTokenPayment(this.invoice, payMethod.userid, payMethod.id)
        .pipe(take(1))
        .subscribe((res) => {
          this.loading.addingTransaction = false;
          if (res) {
            this.alertService.success($localize`Uplata je uspješno dodana na ponudu`);
            this.loadInvoice(true);
          } else {
            this.alertService.error($localize`Došlo je do greške u plaćanju`);
            this.modalRef.hide();
          }
        });
      this.subscription.add(addTranSub);
    } else {
      // If transaction id is defined than its a new card with already resolved payment. Just add invoice payment here
      const addTranSub = this.service.addInvoicePayment(this.invoice, payMethod.transaction_id, payMethod.created_at,
        'monri', this.invoice.total, 0)
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.alertService.success($localize`Uplata je uspješno provedena`);
            this.loadInvoice(true);
          } else {
            this.alertService.error($localize`Došlo je do problema s transakcijom`);
          }
        });
      this.subscription.add(addTranSub);
    }
  }

  goBackToPrevPage(): void {
    this.location.back();
  }
}
