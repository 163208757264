import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidator {
    // Validates URL
    static urlValidator(url): any {
        if (url.pristine) {
            return null;
        }

        const URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

        url.markAsTouched();

        if (URL_REGEXP.test(url.value)) {
            return null;
        }

        return {
            invalidUrl: true
        };
    }

    static domainValidator(url): any {
        if (url.pristine) {
            return null;
        }

        const URL_REGEXP = /^([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

        url.markAsTouched();

        if (URL_REGEXP.test(url.value)) {
            return null;
        }

        return {
            invalidUrl: true
        };
    }

    static numbericValidator(num): any {
        if (num.pristine) {
            return null;
        }
        const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
        num.markAsTouched();

        if (NUMBER_REGEXP.test(num.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    /**
     * example of usage
     * this.form = this.formBuilder.group({
     * name: ['', Validators.required],
     * email:[''],
     * telefone:[''],
     * message:['', Validators.required],
     * }, { validator: atLeastOne(Validators.required, ['email','telefone']) });
     */
    static atLeastOne = (validator: ValidatorFn, controls: string[] = null) => (
        group: FormGroup,
    ): ValidationErrors | null => {
        if (!controls) {
            controls = Object.keys(group.controls);
        }

        const hasAtLeastOne = group && group.controls && controls
            .some(k => !validator(group.controls[k]));

        return hasAtLeastOne ? null : {
            atLeastOne: true,
        };
    };
}
