import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {Currency} from '../../../_models/currency';
import {DomainService} from '../../../domains/domain.service';
import {CartService} from '../../cart.service';
import {AuthenticationService} from '../../../_services';
import {DomainPricing} from '../../../domains/domain-pricing';

@Component({
  selector: 'app-product-domain',
  templateUrl: './product-domain.component.html'
})
export class ProductDomainComponent implements OnInit, OnDestroy {
  @Input() filter: string;
  @Input() isIncluded = false;
  searchForm: FormGroup;
  private subscription: Subscription = new Subscription();
  domainPricingList: DomainPricing[];
  cartCurrency: Currency;
  loading = false;
  submitted = false;
  searchAction = false;
  autoAdd: string;
  addedDomains = 0;
  private searchSubject: BehaviorSubject<string>;
  search$: Observable<string>;
  returnToOptionCid: number;
  domainPattern = '^(?:[a-žA-Ž0-9](?:[a-žA-Ž0-9-]{0,61}[a-žA-Ž0-9]\\.)?)+[a-žA-Ž0-9][a-žA-Ž0-9-]{0,61}[a-žA-Ž0-9]$';

  constructor(
    private formBuilder: FormBuilder,
    private domainService: DomainService,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.searchSubject = new BehaviorSubject<string>('');
    this.search$ = this.searchSubject.asObservable();

    this.subscription.add(
      this.cartService.getCartCurrency()
        .subscribe(currency => {
          this.cartCurrency = currency;

          this.loading = true;
          const pricingSub = this.domainService.getTldPricing(this.cartCurrency.id)
            .pipe(take(1))
            .subscribe(prices => {
              this.loading = false;
              this.domainPricingList = prices;
            });
          this.subscription.add(pricingSub);
        })
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.cartService.domainItems$
        .subscribe(domains => {
          if (domains) {
            this.addedDomains = domains.length;
          }
        })
    );
    const domain = (this.route.snapshot.queryParams.domain !== undefined) ? this.route.snapshot.queryParams.domain : null;
    this.returnToOptionCid = (typeof this.route.snapshot.queryParams.returnOption !== 'undefined') ?
      parseInt(this.route.snapshot.queryParams.returnOption, 10) : null;
    const extension = (this.route.snapshot.queryParams.domainExtension !== undefined) ?
      this.route.snapshot.queryParams.domainExtension : null;

    this.searchForm = this.formBuilder.group({
      search: [domain, [Validators.required, Validators.minLength(2), Validators.pattern(this.domainPattern)]]
    });

    if (domain) {
      this.autoAdd = `${domain}.${extension}`;
      this.search();
    }
  }

  get f() {
    return this.searchForm.controls;
  }

  /**
   * Search for available domains.
   */
  search(e = null, autoAdd: boolean = true) {
    if (e) {
      e.preventDefault();
    }
    if (!autoAdd) {
      this.autoAdd = undefined;
    }
    this.submitted = true;
    if (this.searchForm.invalid || this.f.search.value === this.searchSubject.value) {
      return false;
    }
    const searchArray = this.f.search.value.split('.');
    const searchedExtension = searchArray.length === 2 ? searchArray.pop() : (searchArray.length === 3 ? `${searchArray[1]}.${searchArray[2]}` : null);
    const domain = searchArray.length === 1 ? searchArray[0] : searchArray[0];
    if (domain !== searchedExtension) {
      const dmPCSearched = this.domainPricingList.find(x => x.name === searchedExtension);
      if (dmPCSearched) {
        const indexOfdmPCSearchsd = this.domainPricingList.indexOf(dmPCSearched);
        this.domainPricingList.splice(indexOfdmPCSearchsd, 1);
        this.domainPricingList.unshift(dmPCSearched);
      }
    }
    this.searchSubject.next(domain);
    this.searchAction = true;
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
