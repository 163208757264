<div class="d-flex justify-content-between flex-wrap bd-bottom p-3 bg-white" *ngIf="!reply" [ngClass]="{'hidden': withdraw}">
  <h3 class="font-weight-bold text-color-2" i18n>Novi upit</h3>
</div>
<div class="bd-bottom p-3 d-flex" *ngIf="!reply" [ngClass]="{'hidden': withdraw}">
  <svg-icon src="assets/svg/trokut-napomena.svg" class="icon icon-40 mr-3"></svg-icon>
  <div>
    <p class="mb-2"><span i18n>Na najčešća pitanja smo već odgovorili. </span> <span class="font-weight-bold" i18n>Pogledaj u <a href="{{webUrl}}" appExternalUrl target="_blank">čestim pitanjima</a> prije slanja upita.</span></p>
    <p i18n>Ukoliko ne pronađeš odgovor, pošalji nam upit i pokušaj dati što preciznije i detaljnije informacije kako bismo ti lakše i brže pomogli jer bitno nam je tvoje zadovoljstvo.</p>
  </div>
</div>
<div class="p-3 bg-grey" [ngClass]="{'hidden': withdraw}">
  <form [formGroup]="openTicketForm" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <app-ticket-details [parentForm]="openTicketForm" [submitted]="submitted" [formData]="formData" [isNewTicket]="!ticket"></app-ticket-details>
      </div>

      <div class="d-flex justify-content-between p-3">
          <a class="mr-4 btn btn-blue rounded-light" (click)="cancel()" *ngIf="!submitting" i18n>Odustani</a>
          <button type="submit" class="btn btn-green rounded-light" *ngIf="!submitting" i18n>Pošalji</button>
          <app-loader *ngIf="submitting"></app-loader>
      </div>
  </form>
</div>
