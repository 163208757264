/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthInterceptor } from "./auth-interceptor";
import { EnsureHttpsInterceptor } from "./ensure-https-interceptor";
import { NoopInterceptor } from "./noop-interceptor";
import { CacheInterceptor } from "../_helpers/cache.interceptor";
import { LanguageInterceptor } from "./language.interceptor";
import { OpenReplayInterceptor } from "./open-replay-interceptor";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: OpenReplayInterceptor, multi: true },
];
