import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {AngularSvgIconModule} from 'angular-svg-icon';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {ProductChangePasswordComponent} from './product-change-password.component';
import {LoaderModule} from '../../loader/loader.module';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';

@NgModule({
  declarations: [
    ProductChangePasswordComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    AngularSvgIconModule,
    LoaderModule,
    TooltipModule,
    FontAwesomeModule,
    PasswordStrengthMeterModule
  ],
  exports: [
    ProductChangePasswordComponent
  ]
})
export class ProductChangePasswordModule {}
