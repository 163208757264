<app-cart-progress [step]="step" [productId]="productId" [groupId]="groupId" [cartId]="cartId"
                   *ngIf="step > 0"></app-cart-progress>

<div class="p-1 p-sm-3" [ngClass]="{
  'cart-margin-euro': hasChild
  }">
  <ng-container *ngIf="!hasChild && !loading.groups">
    <h3 class="color-dark-blue mb-3" i18n>Odaberi grupu proizvoda</h3>

    <div class="row">
      <div class="col-md-6 col-lg-4 mb-3" *ngFor="let productSet of productSetsCartView | keyvalue">
        <div class="border rounded-light bg-white shadow p-3 d-flex align-items-center">
          <ng-container [ngSwitch]="productSet.key">
            <svg-icon src="assets/svg/krug-serveri.svg"
                      *ngSwitchCase="'cartSet1_servers'"
                      [svgStyle]="{ 'width.px':70 }"></svg-icon>

            <svg-icon src="assets/svg/krug-email-&-office.svg"
                      *ngSwitchCase="'cartSet4_office'"
                      [svgStyle]="{ 'width.px':70 }"></svg-icon>

            <svg-icon src="assets/svg/krug-cloud.svg"
                      *ngSwitchCase="'cartSet3_cloud'"
                      [svgStyle]="{ 'width.px':70 }"></svg-icon>

            <svg-icon src="assets/svg/krug-web-hosting.svg"
                      *ngSwitchCase="'cartSet2_whost'"
                      [svgStyle]="{ 'width.px':70 }"></svg-icon>
          </ng-container>


          <ul class="pl-3">
            <li>
              <h3 class="color-dark-blue mb-3" *ngIf="productSet.key === 'cartSet1_servers'" i18n>Serveri</h3>
              <h3 class="color-dark-blue mb-3" *ngIf="productSet.key === 'cartSet4_office'" i18n>Office & Support</h3>
              <h3 class="color-dark-blue mb-3" *ngIf="productSet.key === 'cartSet3_cloud'" i18n>Cloud backup</h3>
              <h3 class="color-dark-blue mb-3" *ngIf="productSet.key === 'cartSet2_whost'" i18n>Web Hosting</h3>
            </li>
            <li *ngFor="let productTag of productSet.value; let last = last;" [ngClass]="{'mb-3': !last}">
              <a routerLink="/cart/product" [queryParams]="getProductGroupQueryParams(productTag)" type="button"
                 class="btn-link border-0 shadow-none" [innerHTML]="getGroupNameByTag(productTag)"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="mb-3 text-center" *ngIf="!hasChild && loading.groups">
    <app-loader></app-loader>
  </div>

  <router-outlet></router-outlet>

  <div class="container-fluid" *ngIf="step === 0 && (cartItems?.length > 0 || domainItems?.length > 0)">
    <div class="row">
      <a routerLink="/cart/user" class="col-md-4 offset-md-8 btn rounded-light btn-green" i18n>Nastavi s postojećom
        narudžbom</a>
    </div>
  </div>
</div>


<div class="euro-banner p-2 text-center" *ngIf="hasChild && cartCurrency?.code === 'EUR' && displayHrk">Prema čl. 42 Zakona o uvođenju eura kao službene valute u Republici Hrvatskoj, dvojno iskazivanja cijena prema potrošačima u RH prikazuje se po fiksnom tečaju 1€ = 7,53450kn</div>

